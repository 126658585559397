import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CustomFormControl } from 'src/shared/custom/custom-form-control';
import { PrdComponent } from '../prd/prd.component';
import { Observable, Observer, Subscription, forkJoin, interval, map, observable } from 'rxjs';
import { AppSettings } from 'src/shared/app-settings';
import { MainService } from 'src/shared/services/main.service';
import { PeoplepickerService } from 'src/shared/services/peoplepicker.service';
// import { SelectDynamicTableItem } from 'src/shared/models/select-dynamic-table-item';
// import { SelectedDynamicTableValue } from 'src/app/shared/components/select-dynamic-table/select-dynamic-table.interface';
import { AlertMessageComponent } from 'src/shared/components/alert-message/alert-message.component';
import { PeoplePickerSelectedRow } from 'src/shared/components/peoplepicker-dynamic-table/people-picker-selected-row.interface';
import { DynamicTableAction } from 'src/shared/models/dynamic-table-action';
import { DateFormatterService } from 'src/shared/utils/utils-date';
import { ClientSetupComponent } from '../client-setup/client-setup.component';
import { ProjectOverviewComponent } from '../project-overview/project-overview.component';
import { MercuryOpportunityComponent } from '../mercury-opportunity/mercury-opportunity.component';
import { SharepointObjectNewDeal } from '../../models/sharepoint-object-new-deal';
import { DealTeamObject } from '../../models/dealteam-object';
// import { CompetitorItemObject } from './models/competitor-object';
import { SharepointValidator } from 'src/shared/utils/sharepoint-mapper';
import { ModalComponent } from 'src/shared/components/modal/modal.component';
import { ActivatedRoute } from '@angular/router';
import { NewDealComponent } from '../new-deal/new-deal.component';
import { NewDealSetupSharepointMapper } from 'src/shared/utils/new-deal-setup-sharepoint';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { PeoplePickerUser } from 'src/shared/models/people-picker-user';
import { ClientOverviewComponent } from '../client-overview/client-overview.component';
import { AdminAction } from '../../models/admin-action';
import { AdminReviewComponent } from '../admin-review/admin-review.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { SecurityService } from 'src/shared/services/security.service';
import { NewDealSetupSecurityMatrix } from 'src/shared/utils/new-deal-setup-security-matrix';
import { CurrentUser } from 'src/shared/models/current-user-data';
import { RolesValidatorService } from 'src/shared/utils/new-deal-setup-roles-validator-service';
import { FormStatusValue, StatusValue } from 'src/shared/models/form-status-value';
import { FormActionGroupValue } from '../../models/form-action-group-value';
import { NewDealSetupService } from 'src/shared/services/new-deal-setup.service';
import { ReactivateFormComponent } from 'src/shared/components/reactivate-form/reactivate-form.component';
import { DocumentsComponent } from 'src/shared/components/documents/documents.component';
import { FilesService } from 'src/shared/services/files.service';
import { AdditionalDocumentTypeItemObject } from '../../models/additional-document-type-object';
import { AppContext } from '../../models/app-context';
import { TooltipHelperService } from 'src/shared/services/tooltip-helper.service';
import { NewDealSetupServiceHelper } from 'src/shared/services/new-deal-setup-service-helper';
import { DocumentTypeItem } from 'src/shared/models/document-type-item';
import { MarketOfferingMap } from 'src/shared/utils/market-offering-map';
import { FormListFields } from '../../models/form-list-fields';
import { SharepointObjectMyDeal } from '../../models/sharepoint-object-my-deal';
import { AdminAssigned } from '../../models/admin-assigned';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { DefaultFieldsValues } from 'src/shared/models/default-fields-values';
import { DefaultValues } from 'src/shared/utils/default-values';
import { AttachmentItem } from 'src/shared/models/attachment-item';
//import { HttpClient } from '@angular/common/http';
import { timer } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AdminService } from 'src/app/services/admin.service';
import { NewdealService } from 'src/app/services/newdeal.service';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { NotifyPopupComponent } from 'src/shared/components/alerts/notify-popup/notify-popup.component';
import { arrow } from '@popperjs/core';
import * as moment from 'moment';
import { AlertService } from '../../_alert';
import { NewDealNotifyPopupComponent } from 'src/shared/components/alerts/newdeal-notify-popup/newdeal-notify-popup.component';
import { DateService } from 'src/app/services/date.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { EngagementHealthObjectServiceService } from 'src/app/services/engagement-health-object-service.service';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/groups/cfb660ea-e955-421f-bb47-d7d091376443/members?$select=displayName,jobTitle,userPrincipalName&$top=200';
const GRAPH_ENDPOINT_firstpart = 'https://graph.microsoft.com/v1.0/groups/';
const GRAPH_ENDPOINT_secondpart='/members?$select=displayName,jobTitle,userPrincipalName&$top=999';
//const lcsp_ENDPOINT_secondpart='/members?$select=displayName,jobTitle,userPrincipalName';
const modalTypes  = {
  submit: 'submit',
  mandatoryFields: 'mandatory-fields',
  retrieve: 'retrieve',
  discontinue: 'discontinue',
  discontinueReason: 'discontinue-reason',
  discontinueConfirmed: 'discontinue-confirmed',
  returnForRework: 'return-for-rework',
  returnForReworkReason: 'return-for-rework-reason',
  returnForReworConfirmed: 'return-for-rework-confirmed',
  approval: 'approval',
  access: 'access',
  noDCFMember: 'no-dcf-member',
  userLeftDcf: 'user-left-dcf-member',
  reactivateForm: 'reactivate-form',
  reactivateConfirmed: 'reactivate-confirmed',
  filesUploading: 'files-uploading',
  error: 'error',
  save: 'save',
  noValidForm: 'no-valid-form',
  formUpdated: 'form-updated',
  retryWorkflow: 'retry-workflow',
  validateSwiftRoles : 'validate-swift-roles',
  activeNewDealNotification:'activeNewDealNotification',
  lcspmismatch:'lcsp-mismatch'
};

const formGroups = {
  projectOverViewFormGroup: 'projectOverViewFormGroup',
  clientOverViewFormGroup: 'clientOverViewFormGroup',
  newDealFormGroup: 'newDealFormGroup',
  mercuryOpportunityFormGroup: 'mercuryOpportunityFormGroup',
  clientSetupFormGroup: 'clientSetupFormGroup',
  adminReviewFormGroup: 'adminReviewFormGroup',
  documentsFormGroup: 'documentsFormGroup',
  myDealFormGroup: 'myDealFormGroup',
  activeNewDealFormGroup:'activeNewDealFormGroup'
};

const responseCodes = {
  ok: 'ok',
  conflicts: 'conflicts',
  tryAgain: 'tryAgain',
  fail: 'fail',
  formUpdated: 'formUpdated',
  incomingUpdates: 'incomingUpdates',
  noDCFMember: 'no-dcf-member',
  noValidForm: 'no-valid-form',
  returnForReworkReasonEmpty: 'return-for-rework-reason-empty',
  filesUploading: 'files-uploading'
};
const alertOptions = {
  autoClose: true,
  keepAfterRouteChange: false
};
const alertTypes = {
  success: 'success',
  warning: 'warning',
  primary: 'primary',
  danger: 'danger'
};

const dealTeamSwiftRoles = [

]

@Component({
  selector: 'app-newdeal-setup',
  templateUrl: './new-deal-setup.component.html',
  styleUrls: ['./new-deal-setup.component.scss'],
  providers: [DatePipe]
})
export class NewDealSetupComponent implements OnInit, OnDestroy {

  formId: number;
  engagementId: number;
  myDealFormId: number;
  formStatus: string =AppSettings.initialFormStatus;
  formActionGroup = AppSettings.initialFormActionGroup
  discontinueReason = '';
  returnForReworkReason = '';
  appFormGroup: any;
  saveInProgress: boolean;
  saveButtonVisible: boolean;
  currentUser: CurrentUser;
  adGroupMember:any;
  lcspGroup1:any;
  lcspGroup2:any;
  isAutoSaveEnabled:any=false;
  isAutoSaveUpdate:any=false;
  lcspGroup:any;
  test:string;
  isAdminReviewEditable:any=false;
  requesturl:any;
  isAutoSaveSpinnerEnabled:boolean=false;
  appContext: AppContext;
  modalType: string;
  subscription: Subscription;
  sharePointMapper: NewDealSetupSharepointMapper;
  formSubmitted: boolean;
  initialNewDealList:any;
  initialMyDealList:any;
  initialFormValidators: Array<SharepointValidator>;
  dealTeamList: Array<PeoplepickerDynamicTableItem> = [];
  dealTeamRow: PeoplePickerSelectedRow;
  adminAssignedId: number;
  initialNewDealSetupObject: SharepointObjectNewDeal | any;
  initialMyDealObject:SharepointObjectMyDeal
  initialDealTeamTable: Array<PeoplepickerDynamicTableItem> = [];
  //initialCompetitorsTable: Array<SelectDynamicTableItem> = [];
  initialAttachments: Array<DocumentTypeItem> = [];
  initialAdditionalDocumentTypes: Array<DocumentTypeItem> = [];
  conflictInputsOnSave: boolean;
  overwriteInputs: boolean;
  differences: any = {};
  alertMessageType = alertTypes.success;
  executingWorkflowChangeAction = false;
  discontinuedFormActionGroup: string;
  discontinuedFormStatus: string;
  requestSaveFromDealTeam:boolean = false;
  isAllSwiftRolesSelected: boolean = true;
  isNotificationSent:boolean;
  isNewDealFormAdmin:boolean = false;
  isDealTeamMember : boolean = false;
  isFormS24User : boolean = false;
  userRoles:any;
  userRoleList:any = [];
  userCurrentSection:any='#project-overview';
  dealTeamMembers:any;
  currentUserEmail:any;
  firstColumnItemList:any;
  secondColumnItemList:any;
  thirdColumnItemList:any;
  isServiceDelaySpinnerShow:any=false;
  adminAssigned:any;
  buttonsVisible:boolean = true;
  currentUserName:any;
  showNewDealpopup:boolean=true;
  showDealWBSpopup:boolean=false;
  showCCLListpopup:boolean=false;
  @ViewChild(PrdComponent, { static: true })
  prdComponent: PrdComponent;
  isADGroupUser:boolean|null=false;

  @ViewChild(ClientSetupComponent, { static: true })
  clientSetupComponent: ClientSetupComponent;

  @ViewChild(ProjectOverviewComponent, { static: true })
  projectOverviewComponent: ProjectOverviewComponent;

  @ViewChild(MercuryOpportunityComponent, { static: true })
  mercuryOpportunityComponent: MercuryOpportunityComponent;

  @ViewChild(NewDealComponent, { static: true })
  newDealComponent: NewDealComponent;

  @ViewChild(ClientOverviewComponent, { static: true })
  clientOverviewComponent: ClientOverviewComponent;

  @ViewChild(AdminReviewComponent, { static: false })
  adminReviewComponent: AdminReviewComponent;

  @ViewChild(SidebarComponent, { static: true })
  sidebarComponent: SidebarComponent;

  @ViewChild(ReactivateFormComponent, { static: true })
  reactivateFormComponent: ReactivateFormComponent;

  @ViewChild(DocumentsComponent, { static: true })
  documentsComponent: DocumentsComponent;

  @ViewChild('Alert')
  alert: AlertMessageComponent;
  @ViewChild(NewDealNotifyPopupComponent, { static: true }) notifypopupComponent: NewDealNotifyPopupComponent;

  @ViewChild('Modal')
  modal: ModalComponent;


  usercomments: Array<any> = [];
  documentTypes: any;
  s24GroupUsers: any;
  DiscontinuedOn: any;
  DiscontinuedBy: any;
  Created:any;
  myDealCreatedDate: any;
  isRedirect:any
  adminAssignedDisplayName: any;
  formType:any = AppSettings.FormTypes.NewDealSetupForm;

  constructor(
    private dateService: DateService,
   private httpClient: HttpClient,
   private dropdownService: DropdownService,
    private authService:MsalService,
    private mainService: MainService,
    private adminService:AdminService,
    private peoplepickerService: PeoplepickerService,
    private dateFormatterService: DateFormatterService,
    private securityService: SecurityService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private rolesValidator: RolesValidatorService,
    private changeDetector: ChangeDetectorRef,
    private newDealSetupService: NewDealSetupService,
    private newDealService:NewdealService,
    private fileService: FilesService,
    private tooltipHelperService: TooltipHelperService,
    private newDealSetupServiceHelper: NewDealSetupServiceHelper,
    private marketOfferingMap: MarketOfferingMap,
    private defaultValues: DefaultValues,
    private alertService:AlertService,
    private commonHelperService:CommonHelperService,
    private EHOService:EngagementHealthObjectServiceService,
    public globalHelperService:GlobalHelperService
  ) { }

  ngOnInit() {
   this.UIActions();
    this.spinnerService.showAutoSaveSpinner();
    this.currentUserEmail = localStorage.getItem("userProfileId")?.toString();
    localStorage.setItem("CurrentAccessPage", "NewDealForm");
    this.globalHelperService.currentAccessPage="NewDealForm";
    this._createForm();
    this.loadUIInitialHelpers();
    this.commonHelperService.getUserGroupMembership().subscribe((res:any)=> {
      if(res!=null && res!=undefined){
        this.isADGroupUser = res?.isADGroupUser;
        this.myDealFormId =  res?.myDealID;

        this.getAdGroup();  
        this.getS24GroupData();
      }    
    });  
    //this.getAdGroup();
    this.currentUserName = localStorage.getItem("NewDealAuditorName")?.toString();
    //this.getDocumentTypes();
  }

  ngOnDestroy() {
    this._stopAutosave();
  }
  loadURLFormID(){
   let data=window.location.search.substring(1);
   if(data!=""&&data!=undefined){
   let splitData=data.split('=');
   localStorage.setItem('formId', splitData[1].toString());
   this.globalHelperService.formID=splitData[1].toString();
   }
  }
  getAdGroup() {
    if(this.isADGroupUser)
      {
        this._setFormId();
        this.globalHelperService.formID = this.formId.toString();
        localStorage.setItem('formId', this.formId.toString());
        this.initializeSecurityAndGetUserRoles();
      }
      else
      {
        this.spinnerService.hideAutoSaveSpinner();
        this._openModal(modalTypes.noDCFMember);
        this.spinnerService.hideAutoSaveSpinner();
      }
    // forkJoin([
    //    //this.mainService.getCurrentUserData(),
    //    //this.commonHelperService.getUserData(),
    //    this.mainService.getAppConfigurationValue(AppSettings.ContentManagementFormTypes.NewDeal)]).
    //    subscribe({
    //    next:([applicationContext]) =>{
    //    //this.currentUser = user;
    //    this.appContext = applicationContext;
    //    if(this.isADGroupUser)
    //     {
    //       this._setFormId();
    //       localStorage.setItem('formId', this.formId.toString());
    //       this.initializeSecurityAndGetUserRoles();
    //     }
    //     else
    //     {
    //       this.spinnerService.hideAutoSaveSpinner();
    //       this._openModal(modalTypes.noDCFMember);
    //       this.spinnerService.hideAutoSaveSpinner();
    //     }
    //    //GRAPH_ENDPOINT = GRAPH_ENDPOINT.replace('{{AdGroupId}}',this.appContext.adGroupId);
    //   //  this.newDealService.GetDealADGroupDetails().subscribe({
    //   //   next: (profile: any) => {
    //   //     this.adGroupMember = profile;
    //   //     if (this._isDCFMemberInAdGroup(this.currentUser.email,this.adGroupMember.value)) {
    //   //      localStorage.setItem('adGroupMember',JSON.stringify(this.adGroupMember.value));
    //   //       this._setFormId();
    //   //       localStorage.setItem('formId', this.formId.toString());
    //   //       this.initializeSecurityAndGetUserRoles();
    //   //      }
    //   //       else {
    //   //         this.spinnerService.hideAutoSaveSpinner();
    //   //        this._openModal(modalTypes.noDCFMember);
    //   //        this.spinnerService.hideAutoSaveSpinner();
    //   //    }
    //   //   },
    //   //   error: (err: any) => {
    //   //     if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
    //   //     this.spinnerService.hideAutoSaveSpinner();
    //   //     console.log("Error" + err);
    //   //   }


    //   //         // let count=0;
    //   //    //this.requesturl=GRAPH_ENDPOINT_firstpart+environment.lcspadGroupId1+GRAPH_ENDPOINT_secondpart;

    //   // //    while(this.requesturl){
    //   // //    this.httpClient.get(this.requesturl)
    //   // //    .toPromise().then(response => {
    //   // //     this.lcspGroup1 = response;
    //   // //     if(count == 0){
    //   // //     this.lcspGroup= this.lcspGroup1.value;
    //   // //     }
    //   // //     else
    //   // //     this.lcspGroup=this.lcspGroup.concat(this.lcspGroup1.value);
    //   // //     if(response['@odata.nextLink']){
    //   // //       this.requesturl = response['@odata.nextLink'];
    //   // //     }
    //   // //     else{
    //   // //       this.requesturl = false;
    //   // //       localStorage.setItem('lcspGroup1',JSON.stringify(this.lcspGroup));
    //   // //     }
    //   // //     count=count+1;
    //   // //   });
    //   // // }
    //   //     //localStorage.setItem('lcspGroup1',JSON.stringify(this.lcspGroup1.value));
    //   //     //let headers = new Headers({ 'ConsistencyLevel': 'eventual'});
    //   //   //  this.httpClient.get(GRAPH_ENDPOINT_firstpart+environment.lcspadGroupId1+'/members?$search="userPrincipalName:s"&$select=displayName,jobTitle,userPrincipalName&$top=999')
    //   //   //  .toPromise().then(res=>{
    //   //   //   this.lcspGroup2 = res;
    //   //   //   localStorage.setItem('lcspGroup2',JSON.stringify(this.lcspGroup2.value));

    //   //   //  });

    //   //   //  this.httpClient.post(GRAPH_ENDPOINT_firstpart+environment.lcspadGroupId2+GRAPH_ENDPOINT_secondpart,'Sun')
    //   //   //  .toPromise().then(res=>{
    //   //   //   this.lcspGroup = res;
    //   //   //   localStorage.setItem('lcspGroup',JSON.stringify(this.lcspGroup2.value));
    //   //   //  });
    //   //  });
    //   },
    //   error: error => {
    //     this.spinnerService.hideAutoSaveSpinner();
    //      console.error('Error:', error);
    //    }
    // });
  }
 loadUIInitialHelpers(){
  this.tooltipHelperService.getTooltips();
 }
 UIActions(){
  document.getElementById("dashboardLink")?.scrollIntoView({behavior:'smooth'})
  document.body.style.overflow ="hidden";
  //this.loadURLFormID();
 }
   initializeSecurity():Promise<any> {
      const url = window.location.href;
      const formID = url.split('?')[1]?.split("=")[1];

     return  new Promise<any>((resolve, reject) => {
      if(formID != undefined)
      {    
        forkJoin([
          this.newDealService.GetNewDealDetails(formID),
          this.EHOService.GetEHOObjects(null,this.myDealFormId),
          this.mainService.getAppConfigurationValueObservable(AppSettings.ContentManagementFormTypes.NewDeal)
        ]).subscribe({
            next:([result,ehoobjectresult,applicationContext])=>{
              this.appContext = applicationContext;
              this.initialNewDealList=result[0];
              this.engagementId = ehoobjectresult[0].ID;
            if(result.length==0){
              this.spinnerService.hideAutoSaveSpinner();
              this._openModal(modalTypes.noValidForm);
              this.buttonsVisible=false;
            }
            //this.test=result[0].MyDealClientName;
            if(this.initialNewDealList!=undefined)
            {
            this.mainService.myDealFormId = this.myDealFormId =this.initialNewDealList.MyDealID;
            // this.EHOService.GetEHOObjects(null,this.myDealFormId).subscribe({
            //   next:(result:any)=>{
            //     this.engagementId = result[0].ID;
            //   },error:(error:any)=>{console.error('error getting engagement Data');}
            // });
            this.mainService.formId = this.formId = this.initialNewDealList.ID;
            this.formStatus = this.initialNewDealList.FormStatus;
            this.adminAssigned = this.initialNewDealList.AdminAssigned;
            this.adminAssignedDisplayName = this.initialNewDealList.AdminAssignedDisplayName;
            this.formActionGroup = this.initialNewDealList.FormActionGroup;
            this.dealTeamMembers = this.initialNewDealList.DealTeamMembers?.split(';#');
            if(this.dealTeamMembers!=undefined && this.dealTeamMembers.length > 0)
            {
              //case insensitive check
              if (this.dealTeamMembers && this.currentUserEmail) 
                {
                 this.isDealTeamMember = this.dealTeamMembers
                  .filter((member: string) => member != null && member != undefined)
                  .map((member: string) => member.toLowerCase())
                  .includes(this.currentUserEmail.toLowerCase());
              } else {
                this.isDealTeamMember = false;
              }
               if(this.isDealTeamMember)
               {
                this.userRoleList.push(AppSettings.dealTeamMembersGroup);
               }
            }
            let s24UserAssigned = this.initialNewDealList.Series24;
            if(s24UserAssigned!=undefined)
            {
              if(s24UserAssigned.toLowerCase() == this.currentUserEmail.toLowerCase())
              {
                this.isFormS24User = true;
                this.userRoleList.push(AppSettings.series24Group);
              }
            }
          }     
          else{
            this.userRoleList.push(this.rolesValidator.validateFormRoles(this.userRoles, this.formId, this.formId, this.myDealFormId));
          }
            var formStatus = this.mainService.getFormStatus(this.formId,this.formStatus,this.formActionGroup)
            if(formStatus.isValid)
            {
              this.securityService.getPermissions(this.currentUserEmail,this.formStatus, this.formActionGroup, this.userRoleList, NewDealSetupSecurityMatrix);
              this.securityService.securityMatrixEvent.subscribe(response => {
                if(this.formId!==undefined){this.LoadDropdownLists();}
                resolve(this.initializeForm(this.initialNewDealList));
              });
            }
            else
            {
              //This should be later replaced with update .net api
              this.newDealSetupService.updateFormOnRetryWorkflow(this.formStatus, this.formId,this.initialNewDealList.Created)
              .then((response) => {
                if (response) {
                  this._openModal(modalTypes.retryWorkflow);
                }
                this.spinnerService.hideAutoSaveSpinner();
              });
            }
            //this.initializeForm(result[0]);
            //this.initialMyDealObject=result;
            },
            error: (err: any) => {
              this.spinnerService.hideAutoSaveSpinner();
              if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}
              console.log("Error" + err);
          }
        });
        // this.newDealService.GetNewDealDetails(formID).subscribe({
        //   next: (result: any) => {
        //     this.initialNewDealList=result[0];
        //     if(result.length==0){
        //       this.spinnerService.hideAutoSaveSpinner();
        //       this._openModal(modalTypes.error);
        //       this.buttonsVisible=false;
        //     }
        //     //this.test=result[0].MyDealClientName;
        //     if(this.initialNewDealList!=undefined){
        //     this.mainService.myDealFormId = this.myDealFormId =this.initialNewDealList.MyDealID;
        //     this.EHOService.GetEHOObjects(null,this.myDealFormId).subscribe({
        //       next:(result:any)=>{
        //         this.engagementId = result[0].ID;
        //       },error:(error:any)=>{console.error('error getting engagement Data');}
        //     });
        //     this.mainService.formId = this.formId = this.initialNewDealList.ID;
        //     this.formStatus = this.initialNewDealList.FormStatus;
        //     this.adminAssigned = this.initialNewDealList.AdminAssigned;
        //     this.adminAssignedDisplayName = this.initialNewDealList.AdminAssignedDisplayName;
        //     this.formActionGroup = this.initialNewDealList.FormActionGroup;
        //     this.dealTeamMembers = this.initialNewDealList.DealTeamMembers?.split(';#');
        //     if(this.dealTeamMembers!=undefined && this.dealTeamMembers.length > 0)
        //     {
        //        this.isDealTeamMember = this.dealTeamMembers.includes(this.currentUserEmail);
        //        if(this.isDealTeamMember)
        //        {
        //         this.userRoleList.push(AppSettings.dealTeamMembersGroup);
        //        }
        //     }
        //     let s24UserAssigned = this.initialNewDealList.Series24;
        //     if(s24UserAssigned!=undefined)
        //     {
        //       if(s24UserAssigned.toLowerCase() == this.currentUserEmail.toLowerCase())
        //       {
        //         this.isFormS24User = true;
        //         this.userRoleList.push(AppSettings.series24Group);
        //       }
        //     }
        //   }     
        //   else{
        //     this.userRoleList.push(this.rolesValidator.validateFormRoles(this.userRoles, this.formId, this.formId, this.myDealFormId));
        //   }
        //     var formStatus = this.mainService.getFormStatus(this.formId,this.formStatus,this.formActionGroup)
        //     if(formStatus.isValid)
        //     {
        //       this.securityService.getPermissions(this.currentUserEmail,this.formStatus, this.formActionGroup, this.userRoleList, NewDealSetupSecurityMatrix);
        //       this.securityService.securityMatrixEvent.subscribe(response => {
        //         if(this.formId!==undefined){this.LoadDropdownLists();}
        //         resolve(this.initializeForm(this.initialNewDealList));
        //       });
        //     }
        //     else
        //     {
        //       //This should be later replaced with update .net api
        //       this.newDealSetupService.updateFormOnRetryWorkflow(this.formStatus, this.formId,this.initialNewDealList.Created)
        //       .then((response) => {
        //         if (response) {
        //           this._openModal(modalTypes.retryWorkflow);
        //         }
        //         this.spinnerService.hideAutoSaveSpinner();
        //       });
        //     }
        //     //this.initializeForm(result[0]);
        //     //this.initialMyDealObject=result;
        //   },
        //   error: (err: any) => {
        //     this.spinnerService.hideAutoSaveSpinner();
        //     if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}
        //     console.log("Error" + err);
        //   }
        // });
      }
      else
      {
        this.mainService.getAppConfigurationValueObservable(AppSettings.ContentManagementFormTypes.NewDeal).
        subscribe((applicationContext)=>{
          this.appContext = applicationContext;
          //Added below to show Save button in sidebar
        this.isAdminReviewEditable=true;
        //adding this role to get dealteam member permission by default
        //This will be executed for new form without ID
        this.userRoleList.push(AppSettings.dealTeamMembersGroup);

        var formStatus = this.mainService.getFormStatus(this.formId,this.formStatus,this.formActionGroup)
        if(formStatus.isValid)
        {
          this.securityService.getPermissions(this.currentUserEmail,this.formStatus, this.formActionGroup, this.userRoleList, NewDealSetupSecurityMatrix);
          this.securityService.securityMatrixEvent.subscribe(response => {
            if(this.formId!==undefined){this.LoadDropdownLists();}
           resolve(this.initializeForm(this.initialNewDealList));
          });
        }
        this.spinnerService.hideAutoSaveSpinner();
        });
        
      }

    // Promise.all([
    //   this.mainService.retrieveForm(AppSettings.formListName, this.formId)])
    //   .then(([responseFormItem]) => {
    //     if (responseFormItem) {
    //       this.myDealFormId = responseFormItem[AppSettings.myDealSiteColumn];
    //     }
    //     Promise.all([
    //       this.rolesValidator.validateFormRoles(this.currentUser.groups, this.currentUser.id, this.formId, this.myDealFormId),
    //       this.mainService.getFormStatus(AppSettings.formListName, this.formId)])
    //       .then(([roleList, status]) => {
    //         if (status.isValid) {
    //           this.securityService.getPermissions(status.formStatus, status.formActionGroup, roleList, NewDealSetupSecurityMatrix);
    //           this.securityService.securityMatrixEvent.subscribe(response => {
    //             this.initializeForm(responseFormItem);
    //           });
    //         } else {
    //           this.newDealSetupService.updateFormOnRetryWorkflow(status.formStatus, this.formId)
    //             .then((response) => {
    //               if (response) {
    //                 this._openModal(modalTypes.retryWorkflow);
    //               }
    //               this.spinnerService.hide();
    //             });
    //         }
    //       });
    //   }).catch(error => {
    //     this._openModal(modalTypes.error);
    //     this.spinnerService.hide();
    //   });
      });
  }
  appendLCSPValues(response:any){
    this.initialNewDealList.LeadBusinessPartnerId={};this.initialNewDealList.LeadClientServicePartnerId={};this.initialNewDealList.GlobalLCSPId={};
    this.initialNewDealList.LeadBusinessPartnerId.results=[];this.initialNewDealList.LeadClientServicePartnerId.results=[];;this.initialNewDealList.GlobalLCSPId.results=[];
   this.initialNewDealList.LeadBusinessPartnerId.results= this._getLCSPPeoplepickerUserDataForNewDeal(response.LeadBusinessPartner,response.LeadBusinessPartnerDisplayNames);
   this.initialNewDealList.LeadClientServicePartnerId.results=  this._getLCSPPeoplepickerUserDataForNewDeal(response.LeadClientServicePartner,response.LeadClientServicePartnerDisplayNames);
   this.initialNewDealList.GlobalLCSPId.results= this._getLCSPPeoplepickerUserDataForNewDeal(response.GlobalLCSP,response.GlobalLCSPDisplayNames);
   if(this.initialNewDealList.leadBusinessPartner==""||this.initialNewDealList.LeadBusinessPartnerDisplayNames==""){this.initialNewDealList.LeadBusinessPartnerDisplayNames=null;this.initialNewDealList.LeadBusinessPartner=null;}
   if(this.initialNewDealList.LeadClientServicePartner==""||this.initialNewDealList.LeadClientServicePartnerDisplayNames==""){this.initialNewDealList.LeadClientServicePartner=null;this.initialNewDealList.LeadClientServicePartnerDisplayNames=null;}
   if(this.initialNewDealList.GlobalLCSP==""||this.initialNewDealList.GlobalLCSPDisplayNames==""){this.initialNewDealList.GlobalLCSP=null;this.initialNewDealList.GlobalLCSPDisplayNames=null;}
   return this.initialNewDealList;
  }
  appendAutoSaveLCSPValues(response:any){
    response.LeadBusinessPartnerId={};response.LeadClientServicePartnerId={};response.GlobalLCSPId={};
    response.LeadBusinessPartnerId.results=[];response.LeadClientServicePartnerId.results=[];;response.GlobalLCSPId.results=[];
   response.LeadBusinessPartnerId.results= this._getLCSPPeoplepickerUserDataForNewDeal(response.LeadBusinessPartner,response.LeadBusinessPartnerDisplayNames);
   response.LeadClientServicePartnerId.results=  this._getLCSPPeoplepickerUserDataForNewDeal(response.LeadClientServicePartner,response.LeadClientServicePartnerDisplayNames);
   response.GlobalLCSPId.results= this._getLCSPPeoplepickerUserDataForNewDeal(response.GlobalLCSP,response.GlobalLCSPDisplayNames);
   if(response.leadBusinessPartner==""||response.LeadBusinessPartnerDisplayNames==""){response.LeadBusinessPartnerDisplayNames=null;response.LeadBusinessPartner=null;}
   if(response.LeadClientServicePartner==""||response.LeadClientServicePartnerDisplayNames==""){response.LeadClientServicePartner=null;response.LeadClientServicePartnerDisplayNames=null;}
   if(response.GlobalLCSP==""||response.GlobalLCSPDisplayNames==""){response.GlobalLCSP=null;response.GlobalLCSPDisplayNames=null;}
   return response;
  }
  initializeForm(responseFormItem:any) {
   if(responseFormItem!=undefined) {responseFormItem=this.appendLCSPValues(responseFormItem);}
    this._initialSetValidators(); //set-up validators for required fields to each formcontrol by iterating form groups
    this._createSharepointMapper(); // create mapping for sharepoint column names and formcontrol names
    this._setSharepointMapper();
    this._initializeFieldValues({});
      Promise.all([this.mainService.getDocumentTypes(),
                  this.newDealSetupService.getMarketOfferingValues()])
      .then(([responseDocumentTypes, responseMarketOffering]) => {
        this.documentTypes = responseDocumentTypes;
        this._populateDocumentTypes(responseDocumentTypes);
        this._handlerRetrieve(responseFormItem);
        this.marketOfferingMap.setValuesToMap(responseMarketOffering);
      });
    // this.spinnerService.hide();
  }

  initializeSecurityAndGetUserRoles() {
    //this.adminService.GetUserRoleGroups(this.currentUserEmail).subscribe(
      this.commonHelperService.getUserData().subscribe(
      {
        next: (user: any) => {
          if(user.length!=0)
          {
            this.currentUser = user;
            let userGroups = user.groups;
            //userGroups = userGroups.filter((s: any) => s.Title != null);
            this.userRoles = userGroups;
            this.isNewDealAdminUser(userGroups);
            if(this.isNewDealFormAdmin)
            {
                this.userRoleList.push(AppSettings.adminsGroup(this.globalHelperService.currentAccessPage?.toString()));
            }
            this.initializeSecurity(); 
          }
          
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err);
        }
      });
  }
  isNewDealAdminUser(userGroups:any) {
   this.isNewDealFormAdmin = false;
   if(userGroups!=undefined && userGroups.length > 0)
    {
      userGroups.forEach((u:any)=>{
        if(u =="NewDeal Administrators"){
         this.isNewDealFormAdmin = true;
        }
       });
    }
   // this.spinnerService.hideAutoSaveSpinner();//Commented due to calling before full loading
  }
  getDocumentTypes()
  {
  this.mainService.getDocumentTypes().then(
  (responseDocumentTypes)=>{this._populateDocumentTypes(responseDocumentTypes);},

)
  }

  hasPermission(action: string): boolean {
   //return true;
   //to get the data need to comment this temporarily
     return this.securityService.hasPermission(action);
  }

  get formGroups() {
    return formGroups;
  }

  deloitteUsEntityEvent(event:any) {
    const deloitteUsEntitySetManually = this.clientSetupComponent.getDeloitteUsEntitySetManually();
    if (!deloitteUsEntitySetManually) {
      this.clientSetupComponent.setDeloitteUsEntity(event);
    }
  }

  salesOfficeEvent(event:any) {
    const salesOfficeSetManually = this.clientSetupComponent.getSalesOfficeSetManually();
    if (!salesOfficeSetManually) {
      this.clientSetupComponent.setSalesOffice(event);
    }
  }

  offeringEvent(event:any) {
    this.prdComponent.setMarketOffering(event);
  }

  dealTeamRowHandler(dealTeamRow: any) {
    if (this.formId) {
      this._checkDealTeamTypeEvent(dealTeamRow);
    } else {
      this.requestSaveFromDealTeam = true;
      this.dealTeamRow = dealTeamRow;
      this.saveForm().then(response => {
        //this._checkDealTeamTypeEvent(dealTeamRow);
      });
    }
  }

  // competitorItemHandler(competitorItem: SelectedDynamicTableValue) {
  //   if (this.formId) {
  //     this._checkCompetitorTypeEvent(competitorItem);
  //   } else {
  //     this.saveForm().then(response => {
  //       this._checkCompetitorTypeEvent(competitorItem);
  //     });
  //   }
  // }

  // onCompetitorListToDelete(competitorList: SelectDynamicTableItem[]) {
  //   const actionType = DynamicTableAction.Delete;
  //   competitorList.forEach(item => {
  //     const competitorItem: SelectedDynamicTableValue = {
  //       item,
  //       actionType
  //     };
  //     this._deleteCompetitorItem(competitorItem);
  //   });
  // }

  additionalDocumentTypItemHandler(additionalDocumentType: any) {
    if (this.formId) {
      this._storeAdditionalDocumentTypeItem(additionalDocumentType);
    } else {
      this.saveForm().then(response => {
        this._storeAdditionalDocumentTypeItem(additionalDocumentType);
      });
    }
  }

  uploadedAttachmentsHandler(uploadedAttachments:any) {
    this.appFormGroup.get(formGroups.documentsFormGroup)?.get(this.sharePointMapper.getFormControlColumnName(FormListFields.documentTypesAttachments))?.setValue(uploadedAttachments);
  }

  adminActionHandler(adminAction: any) {
    switch (adminAction) {
      case AdminAction.Approve:
        this._adminApprovalAction();
        break;
      case AdminAction.Return:
        this._adminReturnAction();
        break;
      case AdminAction.Discontinue:
        this.onDiscontinue();
        break;
      case AdminAction.Save:
        this.onSaveForm();
        break;
    }
  }
//Below function to Call Save from SideBar
SaveNewDealFormDetails(event: any) {
  if (event) {
    this.onSaveForm();
  }
}
  onSaveForm() {
    this.isRedirect=true;
    this.isServiceDelaySpinnerShow=false;
    this.spinnerService.showAutoSaveSpinner();
    this.mainService.getFormStatus_Bck(AppSettings.formListName, this.formId).then(status => {
      const formStatusChanged = this._formStatusChanged(status.formStatus, status.formActionGroup);
      if (!formStatusChanged) {
        this.saveForm().then(code => {
          if (code === responseCodes.tryAgain) {
            this._subscribeSave();
          } else if (code !== responseCodes.fail) {
            this._openModal(modalTypes.save);
           if(!this.isServiceDelaySpinnerShow){this.spinnerService.hideAutoSaveSpinner();}
          }

        });
      } else {
        this._stopAutosave();
        this._openModal(modalTypes.formUpdated);
        this.spinnerService.hideAutoSaveSpinner();
      }
    });
  }

  selectedFileHandler(fileSelected: boolean) {
    if (this.formId) {
      this._saveFileToLibrary();
    } else {
      this.saveForm().then(response => {
        this._saveFileToLibrary();
      });
    }
  }

  retrieveForm(responseFormItem:any) {
    this.UIActions();
    this.myDealFormId = responseFormItem.MyDealID;
    if (this.myDealFormId) {
      this._initializeNewDealFormValues(responseFormItem);
      this._handlerFormStatus();
      this._handlerAutoSave();
      this.DiscontinuedOn =   responseFormItem.DiscontinuedOn;
      this.DiscontinuedBy = responseFormItem.DiscontinuedBy;
      // this.spinnerService.hide();
      if(responseFormItem.IsActiveNewDealNotificationSent == 'Ready')
          {
            this.isNotificationSent = false;
          }
          else if(responseFormItem.IsActiveNewDealNotificationSent == 'Sent'){
            this.isNotificationSent = true;
          }
      // Promise.all([
      //   this.newDealSetupService.retrieveMyDeal(this.myDealFormId),
      //   this.newDealSetupService.getDealTeamUsers(AppSettings.dealTeamTableList, this.myDealFormId),
      //   //this.newDealSetupService.getCompetitorsList(AppSettings.competitorsList, this.formId),
      //   //this.newDealSetupService.getDCFGroupMembers(),
      //   this.newDealSetupService.getSeries24GroupMembers(),
      //   this.fileService.getFiles(folderUrl),
      //   this.mainService.getFormAdditionalDocumentTypes(this.formId)])
      //   .then(([responseMyDeal, responseDealTeamTable, series24users,
      //           responseAttachments, responseAdditionalDocumentTypes]) => {
          this._initializeFormStatusVariables(responseFormItem);
      //     this.dealTeamList = responseDealTeamTable;
      //     this._populateSections(responseFormItem, responseMyDeal,
      //                            responseAttachments, responseAdditionalDocumentTypes);
      //     this._initializeFieldValues(responseFormItem);
      //     if(responseFormItem.IsActiveNewDealNotificationSent == 'Ready')
      //     {
      //       this.isNotificationSent = false;
      //     }
      //     else if(responseFormItem.IsActiveNewDealNotificationSent == 'Sent'){
      //       this.isNotificationSent = true;
      //     }
      //     this._initializeMyDealInputs(responseMyDeal);
      //     this._storeSharepointObjects(responseDealTeamTable,responseAdditionalDocumentTypes);
      //    this._handlerFormStatus();
      //     this._handlerAdminReviewSection(responseFormItem.AdminApprovalDate, responseFormItem.AdminApprovalName);

      //     this._updateMyDealDataInForm(responseMyDeal.Series24Id, responseDealTeamTable );
      //     this._handlerAutoSave();
      //     this._populateTooltips();
      //     Promise.all([
      //       this._createPeoplePickerUserArrays(),
      //       this._areUsersBelongToDCFGroup(responseFormItem.Series24Id, this.dealTeamList, series24users)
      //     ]).then(([]) => {
      //       this.spinnerService.hide();
      //     });

      //   });
    }
    else {
      this.spinnerService.hideAutoSaveSpinner();
      this._openModal(modalTypes.noValidForm);
    }
    if(this.checkVisibilityActiveNewDealSetion()){
      // this.checkVisibilityActiveNewDealSetion()
      this.sidebarComponent.showActiveNewDealSection();
    }

    if(this._isReactivateFormVisible()){
      this.sidebarComponent.showReactivateFormSection();
    }
  }
  _getFileAndAdminValues(responseFormItem:any,responseDealTeamTable:any,responseAttachments:any,responseAdditionalDocumentTypes:any)
  {
    this._populateSections(responseFormItem, this.initialMyDealList,responseAttachments,responseAdditionalDocumentTypes);
    this._initializeFieldValues(responseFormItem);
    this._initializeMyDealInputs(this.initialMyDealList);
    this._storeSharepointObjects(this.dealTeamList,responseAdditionalDocumentTypes);
    //Commented below for AutoSave initial loading
    //this._storeSharepointObjects(responseDealTeamTable,responseAdditionalDocumentTypes);
    if(!this.isAutoSaveUpdate){ this._openModal(modalTypes.retrieve);}
    this.spinnerService.hideAutoSaveSpinner();
    this._handlerAdminReviewSection(responseFormItem.AdminApprovalDate, responseFormItem.AdminApprovalName); //data binding
    
    //Get file details
    //  var responseAttachments = this.fileService.getFiles(this.formId,AppSettings.FormTypes.NewDealSetupForm);
    //  var responseAdditionalDocumentTypes = this.mainService.getFormAdditionalDocumentTypes(this.formId);
    //  forkJoin([responseAttachments, responseAdditionalDocumentTypes]).subscribe({
    //    next: ([result1,result2]) => {
    //      this._populateSections(responseFormItem, this.initialMyDealList,result1,result2);
    //      this._initializeFieldValues(responseFormItem);
    //      this._initializeMyDealInputs(this.initialMyDealList);
    //      this._storeSharepointObjects(this.dealTeamList,result2);
    //      //Commented below for AutoSave initial loading
    //      //this._storeSharepointObjects(responseDealTeamTable,responseAdditionalDocumentTypes);
    //     if(!this.isAutoSaveUpdate){ this._openModal(modalTypes.retrieve);}
    //     this.spinnerService.hideAutoSaveSpinner();
    //    },
    //    error: error => {
    //     this.spinnerService.hideAutoSaveSpinner();
    //      console.error('Error:', error);
    //    }
    //  });
    //  this._handlerAdminReviewSection(responseFormItem.AdminApprovalDate, responseFormItem.AdminApprovalName); //data binding
  }
  // _getDealTeamValues(responseFormItem:any){
  //   this.newDealService.GetMyDealDetails(this.myDealFormId).subscribe({
  //     next: (result: any) => {
  //       this.initialMyDealList=result[0];
  //      //this._createForm();
  //      // this.initialMyDealObject=result;
  //      this._getFileAndAdminValues(responseFormItem,result[0]);
  //     },
  //     error: (err: any) => {
  //       this.spinnerService.hideAutoSaveSpinner();
  //       if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}
  //       console.log("Error" + err);
  //     }
  //   })
  // }
  _initializeNewDealFormValues(responseFormItem:any):any{
    forkJoin([
      this.newDealService.getDealTeamTableList(this.myDealFormId),
      this.newDealService.GetMyDealDetails(this.myDealFormId),
      this.fileService.getFiles(this.formId,AppSettings.FormTypes.NewDealSetupForm),
      this.mainService.getFormAdditionalDocumentTypes(this.formId)
    ]).subscribe({
      next:([dealTeamResult,myDealResult,responseAttachments,responseAdditionalDocumentTypes])=>
      {
        this.dealTeamList=dealTeamResult;
        this.initialMyDealList=myDealResult[0];
        //this._createForm();
        //this.initialMyDealObject=result;
        this._getFileAndAdminValues(responseFormItem,this.initialMyDealList,responseAttachments,responseAdditionalDocumentTypes);
      },
      error: (err: any) => {
        this.spinnerService.hideAutoSaveSpinner();
        if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}
        console.log("Error" + err);
      }
    });
    // this.newDealService.getDealTeamTableList(formId).subscribe({
    //   next: ((result:any)=>{
    //     this.dealTeamList=result;
    //     this._getDealTeamValues(responseFormItem);
    //     //this._updateMyDealDataInForm(Series24Id, this.dealTeamList );
    //   })
    //   ,
    //   error:((error:any)=>{
    //   })
    //})
  }
  saveForm(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (!this.formId) {
        if (!this.saveInProgress) {
          this.saveInProgress = true;
          this.isServiceDelaySpinnerShow=true;//Added to hold the spinner till the last service call
            this._createDraftForm().then(response => {
              if (response) {
                //after save assigning the current data as the initial page load data.
                const currentNewDealSetupObject = this._setSharepointObjectNewDeal();
                const currentSharepointObjectMyDeal = this._setSharepointObjectMyDeal();
                this.initialNewDealList = currentNewDealSetupObject;
                this.initialMyDealList = currentSharepointObjectMyDeal;
                this.initialNewDealList.FormStatus="Draft";
                this.initialNewDealList.status="Pending for Deal Team Submission";
                this.initialNewDealList.FormActionGroup="DealTeamMembers";
                this.initialNewDealList.Created = this.Created;
                this.initialMyDealList.Created = this.myDealCreatedDate;
                //add current user as dealteam member for newly created newdeal form
                localStorage.setItem('silentnofifyDealTeamRowAdded','true');
                //commented here to complete all the service calls
                //this.projectOverviewComponent.addCurrentUserInDealTeam(this.currentUser);
             
                resolve(this._getResponseCodeOk());
              } else {
                resolve(responseCodes.fail);
              }
              this.saveInProgress = false;
            });
        }
      } else {
        if(this.appContext.autoSaveEnabled){ this.isAutoSaveUpdate=true;}
        this._retrieveLastChangesBeforeSave().then(changes => {
          const canExecuteResponse = this._canExecuteSave(changes);
          const responseCode:any = canExecuteResponse[0];
          const canExecute = canExecuteResponse[1];
          if (canExecute) {
            this.saveInProgress = true;
            this.conflictInputsOnSave = false;
            this.overwriteInputs = false;
            this.differences = this._findDifferencesBeforeSaveData();
            this._updateAppFormGroup(changes).then(() => {
              this._updateFormItem(this.differences).then(responseFormItem => {
                if (responseFormItem) {
                  this._storeSharepointObjects(changes.responseDealTeam, changes.responseAdditionalDocumentTypes);
                  var newDealCreatedDate = this.initialNewDealList.Created;
                  var myDealCreatedDate = this.initialMyDealList.Created;
                  var formStatus = this.initialNewDealList.FormStatus;
                  var formActionGroup = this.initialNewDealList.FormActionGroup;
                  const currentNewDealSetupObject = this._setSharepointObjectNewDeal();
                  const currentSharepointObjectMyDeal = this._setSharepointObjectMyDeal();
                  this.initialNewDealList = currentNewDealSetupObject;
                  this.initialMyDealList = currentSharepointObjectMyDeal;
                  this.initialNewDealList.Created = newDealCreatedDate;
                  this.initialNewDealList.FormStatus = formStatus;
                  this.initialNewDealList.FormActionGroup = formActionGroup;
                  this.initialMyDealList.Created = myDealCreatedDate;
                  this.LoadSeries24Values(currentSharepointObjectMyDeal);
                  resolve(this._getResponseCodeOk());
                } else {
                  resolve(responseCodes.fail);
                  this._stopAutosave();
                }
                this.saveInProgress = false;
                this.LoadURLWithFormID();
              });
            });
          } else {
            resolve(responseCode);
          }
        });
      }
  });
}

  confirmDiscontinueReason() {
    if (this.discontinueReason.trim()) {
      if (!this._getFilesPendingToBeUploaded()) {
        this.spinnerService.showAutoSaveSpinner();
        this.newDealSetupService.updateFormOnDiscontinue(this.formStatus, this.discontinueReason,this.initialNewDealList.Created,this.formId,this.formActionGroup).then(() => {
          this.spinnerService.hideAutoSaveSpinner();
          this._openModal(modalTypes.discontinueConfirmed);
        });
      } else {
        this.closeDiscontinue();
        this._openModal(modalTypes.filesUploading);
      }
    } else {
      this.closeDiscontinue();
      this._openModal(modalTypes.discontinueReason);
    }
  }

  closeDiscontinue() {
    this.discontinueReason = '';
    this._handlerAutoSave();
  }

  cancelDiscontinue() {
    this.closeDiscontinue();
    this.formSubmitted = false;
    this._setValidators(this.initialFormValidators);
  }

  redirectOkModal(modalType: string) {          
   if(modalType==modalTypes.noValidForm)
   {
    this.buttonsVisible=false;
   }
   else
   {
      window.location.href =AppSettings.Engagementdashboard;
   }
  }

  scrollToTop() {
    document.querySelector('#form-body')?.scrollIntoView();
  }

  private _isActiveNewDealVisible(){
    return this.hasPermission('ShowActiveSwiftCodeSection');
  }

  private _isActiveNewDealEditable(){
    return this.hasPermission('EditActiveSwiftCodeSection');
  }

  checkVisibilityActiveNewDealSetion() {
    return this._isActiveNewDealVisible() && this._isActiveNewDealEditable() &&!this._isFormDiscontinuedByDealTeam(this.discontinuedFormActionGroup);
  }

  private _isReactivateFormVisible(){
    return this.hasPermission('Reactivate');
  }

  scrollToSection() {
    const url = window.location.href;
    const section = url.split('aspx#/')[1].split('?')[0];
    if (section && section !== 'main') {
      this.sidebarComponent.onclick('#' + section);
    }
  }

  updateFormStatus(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.saveInProgress = true;
      const formStatusObject = {FormStatus : this.formStatus};
      this.mainService.updateListItem(AppSettings.formListName, this.formId, formStatusObject)
        .then(response  => {
          this.saveInProgress = false;
          resolve(true);
        })
        .catch(error => {
          this.saveInProgress = false;
          reject(false);
        });
    });
  }

  onSubmit() {
    this.spinnerService.showAutoSaveSpinner();
    this._validateDCFUsersBeforeUserAction().then(areValid => {
      if (areValid) {
        this._setValidators(this.initialFormValidators);
        this.formSubmitted = true;
        if (this.appFormGroup.valid) {
          if (this.isAllSwiftRolesSelected) {
            if (!this._getFilesPendingToBeUploaded()) {
              this._stopAutosave();
              this.isRedirect=true;
              return Promise.all([
                this.saveForm(),
                this._adminAssignment()
              ])
                .then(([saveResponse,adminResponse]) => {
                  let adminAssignedObj = this.newDealSetupServiceHelper.createAdminAssignedObjectToSend(adminResponse)
                  Promise.all([
                    this.newDealSetupService.updateFormOnSubmit(adminResponse, adminResponse.assignmentDate,this.initialNewDealList.Created,this.formId),
                    this.mainService.updateForm(AppSettings.myDealFormList,this.myDealFormId,adminAssignedObj,this.initialMyDealList.Created)])
                    .then(([newDealResponse,myDealResponse]) => {
                      this.spinnerService.hideAutoSaveSpinner();
                      newDealResponse && myDealResponse ? this._openModal(modalTypes.submit) : this._openModal(modalTypes.error);
                    });
                });
            } else {
              this.spinnerService.hideAutoSaveSpinner();
              this._openModal(modalTypes.filesUploading);
            }
          } else {
            this.spinnerService.hideAutoSaveSpinner();
            this._openModal(modalTypes.validateSwiftRoles);
          }
        } else {
          this.spinnerService.hideAutoSaveSpinner();
          this._openModal(modalTypes.mandatoryFields);
        }

      } else {
        this.spinnerService.hideAutoSaveSpinner();
        this._openModal(modalTypes.userLeftDcf);
      }
      return
    });
  }

  onDiscontinue() {
    this._setValidators([]);
    this.formSubmitted = true;
    if (this.appFormGroup.valid || this.appFormGroup.status === 'DISABLED') {
      this._stopAutosave();
      this._openModal(modalTypes.discontinue);
    } else {
      this._openModal(modalTypes.mandatoryFields);
    }
  }

  isPendingForAdminReview(): boolean {
    return FormStatusValue.pending === this.formStatus && FormActionGroupValue.adminReview === this.formActionGroup;
  }

  onReturnForRework() {
    this._stopAutosave();
    this._openModal(modalTypes.returnForRework);
  }

  closeReturnForReworkReason() {
    this.returnForReworkReason = '';
    this._handlerAutoSave();
  }

  cancelReturnForRework() {
    this.closeReturnForReworkReason();
    this.formSubmitted = false;
    this._setValidators(this.initialFormValidators);
  }

  checkReasonBeforeReturn() {
    if (this.returnForReworkReason.trim() !== '') {
      if (!this._getFilesPendingToBeUploaded()) {
        this.spinnerService.showAutoSaveSpinner();
        this.saveForm().then(() => {
          const commentObject = this.newDealSetupServiceHelper.createReturnForReworkCommentsObject(this.formId, this.returnForReworkReason);
          this.mainService.createListItem(AppSettings.newDealReviewComments, commentObject).then(data => {
            this.newDealSetupService.updateFormOnReturnForRework(this.returnForReworkReason,this.initialNewDealList.Created,this.formId).then(() => {
              this.spinnerService.hideAutoSaveSpinner();
              this._openModal(modalTypes.returnForReworConfirmed);
            });
          });
        });
      } else {
        this.closeReturnForReworkReason();
        this._openModal(modalTypes.filesUploading);
      }
    } else {
      this.closeReturnForReworkReason();
      this._openModal(modalTypes.returnForReworkReason);
    }
  }

  sicCodeEvent(event:any) {
    const sicCodeSetManually = this.clientSetupComponent.getConsultingSicCodeSetManually();
    if (!sicCodeSetManually) {
      this.clientSetupComponent.setConsultingSicCode(event);
    }
  }

  buyerNameEvent(event:any) {
    const buyerNameSetManually = this.clientSetupComponent.getBuyerNameSetManually();
    if (!buyerNameSetManually) {
      this.clientSetupComponent.setBuyerName(event);
    }
  }

  recurringOpportunityEvent(event:any) {
    const recurringWBSSetManually = this.prdComponent.getRecurringWBSSetManually();
    if (!recurringWBSSetManually) {
      this.prdComponent.setRecurringWBS(event);
    }
  }

  checkVisibilityAdminReviewSetion() {
    return this._isAdminReviewVisible() && !this._isFormDiscontinuedByDealTeam(this.discontinuedFormActionGroup);
  }

  reactivateFormHandler() {
    this._openModal(modalTypes.reactivateForm);
  }

  confirmReactivateForm() {
    this.spinnerService.showAutoSaveSpinner();
    this.mainService.reactivateForm(this.formId,this.initialNewDealList.Created).then(response => {
      this.spinnerService.hideAutoSaveSpinner();
      this._openModal(modalTypes.reactivateConfirmed);
    });
  }

  private _subscribeSave() {
    setTimeout(() => this.onSaveForm(), AppSettings.forceItemReleaseInterval);
  }

  private _initializeFormStatusVariables(responseFormItem:any) {
    this.formStatus = responseFormItem.FormStatus;
    this.formActionGroup = responseFormItem.FormActionGroup;
    this.discontinuedFormActionGroup = responseFormItem.DiscontinuedFormActionGroup;
    this.discontinuedFormStatus = responseFormItem.DiscontinuedFormStatus;
    this.adminAssignedId = responseFormItem.AdminAssignedId;
  }

  private _initializeFieldValues(responseNewDeal:any) {
    this.defaultValues.initializeAppFormGroup(this.appFormGroup, responseNewDeal);
  }

  private _initializeMyDealInputs(responseMyDeal:any) {
    this._initializeControlName(
      formGroups.myDealFormGroup, FormListFields.engagementType, AppSettings.engagementTypeDefaultValue,
       responseMyDeal.EngagementType);
    this._initializeControlName(
      formGroups.myDealFormGroup, FormListFields.integratedMarketOffering, AppSettings.integratedMarketOfferingDefaultValue,
       responseMyDeal.IntegratedMarketOffering);
    this._initializeControlName(
        formGroups.myDealFormGroup, FormListFields.material, AppSettings.materialDefaultValue,
         responseMyDeal.Material);

    //Binding for series24 dropdown
    if(responseMyDeal.Series24 != undefined && responseMyDeal.Series24 != "")
    {
      this.appFormGroup.get(formGroups.myDealFormGroup).controls.series24.setValue(responseMyDeal.Series24);
    }
    else
    {
      this.appFormGroup.get(formGroups.myDealFormGroup).controls.series24.setValue(responseMyDeal.Series24Id);
    }
  }

  private _formStatusChanged(formStatus:any, formActionGroup:any) {
    return (formStatus !== this.formStatus ||
            formActionGroup !== this.formActionGroup);
  }

  private _populateSections(responseFormItem:any, responseMyDeal:any,responseAttachments:any,responseAdditionalDocumentTypes:any ) {
    this._populateMyDealSection(responseMyDeal);
    this._populateProjectOverviewSection(responseFormItem, this.dealTeamList);
    this._populateClientOverviewSection(responseFormItem);
    this._populateNewDealSection(responseFormItem);
    this._populateMercuryOpportunitySection(responseFormItem);
    this._populateClientSetupSection(responseFormItem);
    this._populateAdminReviewSection(responseFormItem);
    this._populateDocumentSection(responseAttachments);
    responseAdditionalDocumentTypes = this._transformAdditionalDocuementsObject(responseAdditionalDocumentTypes)
    this._populateAdditionalDocumentTypes(responseAdditionalDocumentTypes);
    this._isSeries24Selected(responseMyDeal.Series24,this.s24GroupUsers);
  }
  private _transformAdditionalDocuementsObject(responseAdditionalDocumentTypes: any): any {
    // Add documentTypeId to Additional DocumentObject from Master Attachement Data.
    responseAdditionalDocumentTypes.forEach((ele: { documentTypeTitle: any; },index: any)=>
    {
        const attachmentData = this.documentTypes.find((obj: { documentTypeTitle: any; })=> obj.documentTypeTitle==ele.documentTypeTitle);
        if(attachmentData)
        {
          responseAdditionalDocumentTypes[index].documentTypeId=attachmentData.documentTypeId;
          responseAdditionalDocumentTypes[index].documentTypeItemId=attachmentData.documentTypeItemId;
          responseAdditionalDocumentTypes[index].documentTypeRequired=attachmentData.documentTypeRequired;
          responseAdditionalDocumentTypes[index].documentTypeAdditional=attachmentData.documentTypeAdditional;
        }
    });
  return responseAdditionalDocumentTypes;

  }
  private _canExecuteSave(changes:any) {
    let responseCode;
    let executeSave = true;
    switch (true) {
      case this.saveInProgress:
        responseCode = responseCodes.tryAgain;
        executeSave = false;
        break;
      case this._formStatusChanged(changes.responseNewDeal.FormStatus, changes.responseNewDeal.FormActionGroup):
        responseCode = responseCodes.formUpdated;
        executeSave = false;
        break;
    }
    return [responseCode, executeSave];
  }

  private _updateAppFormGroup(changes:any): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        let currentNewDealSetupObject = this._setSharepointObjectNewDeal();
      //update sharepoint objects and apply styles on formcontrols like green for update and orange for conflict values
      this._updateValuesBeforeSave(changes);
      //let currentNewDealSetupObject = this._setSharepointObjectNewDeal();
      const currentSharepointObjectMyDeal = this._setSharepointObjectMyDeal();
      // const changesDealTeamTable = changes.responseDealTeam;
      this.dealTeamList = changes.responseDealTeam;
      //const changesCompetitorTable = changes.responseCompetitorsList;
      const changesAttachments = changes.responseAttachments;
      const changesAdditionalDocumentTypes = changes.responseAdditionalDocumentTypes;
      //Loading LCSP Values
      currentNewDealSetupObject=this.LoadNewDealObject(currentNewDealSetupObject,false);
      //populate updated values into form fields
      if(this.isAutoSaveUpdate){
       this._populateSections(currentNewDealSetupObject, currentSharepointObjectMyDeal,
                             changesAttachments, changesAdditionalDocumentTypes);
       }
      //NewDeal: initialize values for default fields if those are emtpy else update with actual data.
      this._initializeFieldValues(currentNewDealSetupObject);
       //MyDeal: initialize values for default fields if those are emtpy else update with actual data.
      this._initializeMyDealInputs(currentSharepointObjectMyDeal);

      //update the people picker data
      this._createPeoplePickerUserArrays().then(arrays => {
        resolve(true);
      });
      resolve(true);
    });
  }

  private _updateValuesBeforeSave(changes:any) {
    // clean or remove previous applied styles on the fields.
    this.sharePointMapper.cleanInputsEditedExternally(this.appFormGroup);
    //create new sharepoint object and assign last updated response new deal data to newly created object.
    const changesNewDealSetupObject = this._createObjectLastChangesNewDeal(changes.responseNewDeal);
     //create new sharepoint object and assign last updated response My Deal data to newly created object.
    const changesMyDealObject = this._createObjectLastChangesMyDeal(changes.responseMyDeal);
    //get current new deal sharepoint data object
    const currentNewDealSetupObject = this._setSharepointObjectNewDeal();
    //get current My Deal sharepoint data object
    const currentSharepointObjectMyDeal = this._setSharepointObjectMyDeal();
    const currentDealTeamTable = this.projectOverviewComponent.getDealTeamRowsComplete();
    //const currentCompetitorsList  = this.newDealComponent.getCompetitorsRowsComplete();
    const currentAttachments = this.documentsComponent.getAttachmentsRowsComplete();
    const changesAttachments = this._createObjectLastChangesAttachments(changes.responseAttachments);
    //Below is to load LCSP Values
    this.initialNewDealSetupObject = this.appendAutoSaveLCSPValues(this.initialNewDealSetupObject);
    //update the values and apply styles on updated fields
    this._changeValuesIfApply(changesNewDealSetupObject, this.initialNewDealSetupObject,
                              currentNewDealSetupObject, 'newDealSetupDifferences');
    this._changeValuesIfApply(changesMyDealObject, this.initialMyDealObject, currentSharepointObjectMyDeal, 'myDealDifferences');
    this._changeTableValuesIfApply(changes.responseDealTeam, this.initialDealTeamTable,
                                   currentDealTeamTable, AppSettings.dealTeamTableList);
    // this._changeTableValuesIfApply(changes.responseCompetitorsList, this.initialCompetitorsTable,
    //                                currentCompetitorsList, AppSettings.competitorsCustomList );
    this._changeTableValuesIfApply(changesAttachments, this.initialAttachments,
                                   currentAttachments, AppSettings.newdealAttachmentsList);
    this._changeTableValuesIfApply(changes.responseAdditionalDocumentTypes, this.initialAdditionalDocumentTypes,
                                   this.initialAdditionalDocumentTypes, AppSettings.additionalDocumentTypesList);
  }

  private _createObjectLastChangesNewDeal(responseNewDealChanges:any): SharepointObjectNewDeal {
    //create sharepoint object with response data
    const changesNewDealSetupObject = this.newDealSetupServiceHelper.createNewDealSetupObject(responseNewDealChanges);
    //assign the initialized values to properties if those are empty
    const newDealObjectInitiliaze = this.defaultValues.initializeSharepointObject(changesNewDealSetupObject);
    this._setObjectFieldDate(newDealObjectInitiliaze,newDealObjectInitiliaze.ObaPstPersonnelConflictDate,FormListFields.obaPstPersonnelConflictDate);
    this._setObjectFieldDate(newDealObjectInitiliaze,newDealObjectInitiliaze.BackgroundCheckCompletion,FormListFields.backgroundCheckCompletion);
    this._setObjectFieldDate(newDealObjectInitiliaze,newDealObjectInitiliaze.ConflictCheckCompletion,FormListFields.conflictCheckCompletion);
    this._setObjectFieldDate(newDealObjectInitiliaze,newDealObjectInitiliaze.CurrentAuditorTenureExpiryDate,FormListFields.currentAuditorTenureExpiryDate);
    this._setObjectFieldDate(newDealObjectInitiliaze, newDealObjectInitiliaze.OfacCompletion, FormListFields.ofacCompletion);
    this._setObjectFieldDate(newDealObjectInitiliaze, newDealObjectInitiliaze.PursuitStartDate, FormListFields.pursuitStartDate);
    // this._setObjectFieldDate(newDealObjectInitiliaze, newDealObjectInitiliaze.ProjectStartDate, FormListFields.projectStartDate);
    // this._setObjectFieldDate(newDealObjectInitiliaze, newDealObjectInitiliaze.ProjectEndDate, FormListFields.projectEndDate);
    this._setObjectFieldDate(newDealObjectInitiliaze, newDealObjectInitiliaze.ExpectedCloseDate, FormListFields.expectedCloseDate);
    //this._setObjectFieldDate(newDealObjectInitiliaze, newDealObjectInitiliaze.DateBecameClient, FormListFields.dateBecameClient);
    //this._setObjectFieldDate(newDealObjectInitiliaze, newDealObjectInitiliaze.FinancialYearEnd, FormListFields.financialYearEnd);
    this._setObjectFieldDate(newDealObjectInitiliaze, newDealObjectInitiliaze.AuditorStartDate, FormListFields.auditorStartDate);
    this._setObjectFieldPeoplePicker(newDealObjectInitiliaze,newDealObjectInitiliaze.LeadClientServicePartnerId,FormListFields.leadClientServicePartner);
    this._setObjectFieldPeoplePicker(newDealObjectInitiliaze,newDealObjectInitiliaze.LeadBusinessPartnerId,FormListFields.leadBusinessPartner);
    this._setObjectFieldPeoplePicker(newDealObjectInitiliaze, newDealObjectInitiliaze.GlobalLCSPId, FormListFields.globalLCSP);
    return newDealObjectInitiliaze;
  }

  private _createObjectLastChangesMyDeal(responseMyDealChanges:any): SharepointObjectMyDeal {
    const changesMyDealObject = this.newDealSetupServiceHelper.createMyDealObject(responseMyDealChanges);
    const myDealObjectInitialize = this.defaultValues.initializeMyDealSharepointObject(changesMyDealObject);
    this._setObjectFieldDate(myDealObjectInitialize, myDealObjectInitialize.StartDate, FormListFields.projectStartDate);
    this._setObjectFieldDate(myDealObjectInitialize, myDealObjectInitialize.EndDate, FormListFields.projectEndDate);
    return myDealObjectInitialize;
  }

  private _setObjectFieldDate(object:any, fieldValue:any, fieldName:any) {
    if (!!fieldValue) {
      object[fieldName] = this.dateFormatterService.formatDateToSend((fieldValue));
    }
  }

  private _setObjectFieldPeoplePicker(object:any, fieldValue:any, fieldName:any) {
    if (!!!fieldValue) {
      //object[fieldName] = { results: [] };
      object[fieldName] = null;
    } else {
      object[fieldName] = { results: fieldValue.results };
    }
  }

  private _changeValuesIfApply(changesObject:any, initialObject:any, currentObject:any, formObject:any) {
    const changesKeys = Object.keys(changesObject);
    if (changesKeys.length) {
      changesKeys.forEach(input => {
        const initialObjectValue = this._getObjectValue(initialObject[input]);
        const changesObjectValue = this._getObjectValue(changesObject[input]);
        const currentObjectValue = this._getObjectValue(currentObject[input]);
        if (initialObjectValue === currentObjectValue && initialObjectValue !== changesObjectValue) {
          this._overwriteInputWithIncomingValue(input, changesObject[input]);
        } else {
          if (changesObjectValue !== initialObjectValue && changesObjectValue !== currentObjectValue
            && currentObjectValue !== initialObjectValue) {
              this._setInputWithConflict(input, changesObject[input], formObject);
          }
        }
      });
    }
  }

  private _changeTableValuesIfApply(changesTable: Array<any>,
    initialTableData: Array<any>,
    currentTableData: Array<any>,
    table:any) {
    let updateTable = false;
    let sortingParam = "documentTypeId";

    const changesLength = changesTable.length;
    const initialLength = initialTableData.length;
    const currentLength = currentTableData.length;

    if (changesLength > 0 && initialLength === changesLength && currentLength === changesLength) {
        //sorting by documentTypeId
        if(table === AppSettings.newdealAttachmentsList || table === AppSettings.additionalDocumentTypesList ){
            changesTable.sort(this._compareValues(sortingParam));
            initialTableData.sort(this._compareValues(sortingParam));
            currentTableData.sort(this._compareValues(sortingParam));
        }

      updateTable = this._compareValuesTable(changesTable, initialTableData, currentTableData, table);
    } else {
      updateTable = this._compareLengthTableArrays(initialLength, currentLength, changesLength, table);
    }
    if (table === AppSettings.dealTeamTableList) {
      this.projectOverviewComponent.setUpdateDealTeam(updateTable);
    }
    if (table === AppSettings.competitorsCustomList) {
      this.newDealComponent.setUpdateCompetitors(updateTable);
    }
  }

  //This method is used to sort the fileds by property as key.This will not be supported for nested object array
  private _compareValues(key:any, order = 'asc') {
    return (a:any, b:any) => {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  private _compareValuesTable(changesTableData: Array<any>,
    initialTableData: Array<any>,
    currentTableData: Array<any>,
    table: string): boolean {
    let updateTable = false;
    for (let i = 0; i < changesTableData.length; i++) {
      const initialValue = initialTableData[i];
      const changesValue = changesTableData[i];
      const currentValue = currentTableData[i];
      let field;
      if (table === AppSettings.dealTeamTableList) {
        field = FormListFields.dealTeamTable;
      }
      if (table === AppSettings.competitorsCustomList) {
        field = FormListFields.competitorsList;
      }
      if (table === AppSettings.newdealAttachmentsList || table === AppSettings.additionalDocumentTypesList) {
        field = FormListFields.documentTypesAttachments;
      }
      if (!(table === AppSettings.newdealAttachmentsList || table === AppSettings.additionalDocumentTypesList)) {
        if (Object.entries(initialValue).toString() === Object.entries(currentValue).toString() &&
          Object.entries(initialValue).toString() !== Object.entries(changesValue).toString()) {
          this._setInputEditedExternally(field);
          updateTable = true;
          break;
        } else {
          if (Object.entries(changesValue).toString() !== Object.entries(initialValue).toString() &&
            Object.entries(changesValue).toString() !== Object.entries(currentValue).toString() &&
            Object.entries(currentValue).toString() !== Object.entries(initialValue).toString()) {
            this._setInputWithConflict(field);
            updateTable = true;
            break;
          }
        }
      } else {
        let initialObjAttchments: AttachmentItem[] = initialValue.documentTypeAttachments.sort();
        let currentObjAttchments: AttachmentItem[] = currentValue.documentTypeAttachments.sort();
        let externalObjAttchments: AttachmentItem[] = changesValue.documentTypeAttachments.sort();

        if (initialObjAttchments.length === externalObjAttchments.length
          && currentObjAttchments.length === externalObjAttchments.length) {
          for (let i = 0; i < externalObjAttchments.length; i++) {
            const initialObjFileName = initialObjAttchments[i].attachmentName;
            const currentObjFileName = currentObjAttchments[i].attachmentName;
            const changesObjFileName  = externalObjAttchments[i].attachmentName;
            if (initialObjFileName === currentObjFileName && initialObjFileName !== changesObjFileName) {
              this._setInputEditedExternally(field);
              updateTable = true;
              break;
            } else if (initialObjFileName !== currentObjFileName
              && currentObjFileName !== changesObjFileName
              && initialObjFileName !== changesObjFileName) {
              this._setInputWithConflict(field);
              updateTable = true;
              break;
            }
          }

          if(updateTable)
            break;

        }else{
          this._setInputEditedExternally(field);
          updateTable = true;
          break;
        }
      }
    }
    return updateTable;
  }

    private _compareLengthTableArrays(initialLength: number, currentLength: number, changesLength: number, table: string): boolean {
      let updateTable = false;
      let field;
      if (table === AppSettings.dealTeamTableList ) {
        field = FormListFields.dealTeamTable;
      }
      if (table === AppSettings.competitorsCustomList ) {
        field = FormListFields.competitorsList;
      }
      if (table === AppSettings.newdealAttachmentsList || table === AppSettings.additionalDocumentTypesList) {
        field = FormListFields.documentTypesAttachments;
      }
      if (initialLength === currentLength && initialLength !== changesLength) {
        this._setInputEditedExternally(field);
        updateTable = true;
      } else {
        if (initialLength !== currentLength && initialLength !== changesLength  && currentLength !== changesLength) {
          this._setInputWithConflict(field);
          updateTable = true;
        }
      }
      return updateTable;
    }
  private _getObjectValue(value:any) {
    if (value === null || value === undefined) {
      value = '';
    }
    return JSON.stringify(value);
  }

  private _setInputWithConflict(input:any, changesObjectValue?:any, formObject?:any) {
    this.conflictInputsOnSave = true;
    const formControl = this._getFormControl(input);
    if (changesObjectValue) {
      formControl.setValue(changesObjectValue);
      this.differences[formObject][input] = changesObjectValue;
    }
    formControl.setConflictOnMerge(true);
  }

  private _overwriteInputWithIncomingValue(input:any, changesObjectValue:any) {
    const formControl = this._getFormControl(input);
    formControl.setValue(changesObjectValue);
    this._setInputEditedExternally(input);
  }

  private _setInputEditedExternally(input:any) {
    this.overwriteInputs = true;
    const formControl = this._getFormControl(input);
    formControl.setEditedExternally(true);
  }

  private _getFormControl(input:any): CustomFormControl {
    const formGroup = this.sharePointMapper.getFormGroupByName(input, formGroups, this.appFormGroup);
    const formControl = formGroup.get(this.sharePointMapper.getFormControlColumnName(input)) as CustomFormControl;
    return formControl;
  }

  private _createObjectLastChangesAttachments(responseAttachments:any): Array<DocumentTypeItem> {
    const changesAttachmentObject = this.newDealSetupServiceHelper.createAttachmentsObject(responseAttachments,this.documentTypes);
    return changesAttachmentObject;
  }

  private _storeSharepointObjects(responseDealTeamTable:any,responseAdditionalDocumentTypes:any) {
    this.initialNewDealSetupObject = this._setSharepointObjectNewDeal();
    this.initialMyDealObject = this._setSharepointObjectMyDeal();
    if (this.differences.newDealSetupDifferences) {
      const differencesKeys = Object.keys(this.differences.newDealSetupDifferences);
      differencesKeys.forEach(input => {
        this.initialNewDealSetupObject[input] = this.differences.newDealSetupDifferences[input];
      });
      // tslint:disable-next-line: no-string-literal
      delete this.initialNewDealSetupObject['__metadata'];
    }
    this.initialDealTeamTable=responseDealTeamTable;
    //this.initialCompetitorsTable = responseCompetitorsList;
    this.initialAttachments = this.documentsComponent.getAttachmentsRowsComplete();
    this.initialAdditionalDocumentTypes = responseAdditionalDocumentTypes;
  }
  private _retrieveLastChangesBeforeSave(): Promise<any> {
    if (this.formId && this.myDealFormId) {
      if(this.appContext.autoSaveEnabled){//AutoSave Enable retrieval Comparison
        return new Promise((resolve, reject) => {
        Promise.all([ this.newDealService.GetNewDealDetailsForAutoSave(this.formId),
          this.newDealService.GetMyDealDetailsForAutoSave(this.myDealFormId),
          this.newDealService.getDealTeamTableListForAutoSave(this.myDealFormId),
          this.fileService.getFiles(this.formId,AppSettings.FormTypes.NewDealSetupForm),
          this.mainService.getFormAdditionalDocumentTypes(this.formId)]).then(([ resNewDeal,
            resMyDeal,
            dealteamList,
            responseAttachments,
            responseAdditionalDocumentTypes]) => {
              var responseNewDeal=this.appendAutoSaveLCSPValues(resNewDeal[0]);
              var responseMyDeal=resMyDeal[0];
             var responseAttachmentsData =responseAttachments;
             var responseAdditionalDocumentTypesData=responseAdditionalDocumentTypes;
             var responseDealTeam=this._loadActualDealTeamList(responseNewDeal,dealteamList);
                  forkJoin([responseAttachmentsData, responseAdditionalDocumentTypesData]).subscribe({
                    next: ([result1, result2]) => {
                      responseAttachments = result1.length>0?result1:[];
                      responseAdditionalDocumentTypes = result2.length>0?result2:[];
                      resolve({
                        responseNewDeal,
                        responseMyDeal,
                        responseDealTeam,
                        responseAttachments,
                        responseAdditionalDocumentTypes
                      });
                    },
                    error: error => {
                      console.error('Error:', error);
                      // Reject the Promise in case of an error
                      reject(error);
                    }
                  });
          });
        });
      }
      else{
      var responseMyDeal: any = this.initialMyDealList;
      var responseNewDeal: any = this.initialNewDealList;
      var responseDealTeam: any = this.dealTeamList; // initial Deal Team Data
      var responseAttachments: any;
      var responseAdditionalDocumentTypes: any;
      var responseAttachmentsData = this.fileService.getFiles(this.formId,AppSettings.FormTypes.NewDealSetupForm);
      var responseAdditionalDocumentTypesData = this.mainService.getFormAdditionalDocumentTypes(this.formId);

      return new Promise((resolve, reject) => {
        forkJoin([responseAttachmentsData, responseAdditionalDocumentTypesData]).subscribe({
          next: ([result1, result2]) => {
            responseAttachments = result1.length>0?result1:[];
            responseAdditionalDocumentTypes = result2.length>0?result2:[];
            resolve({
              responseNewDeal,
              responseMyDeal,
              responseDealTeam,
              responseAttachments,
              responseAdditionalDocumentTypes
            });
          },
          error: error => {
            console.error('Error:', error);
            // Reject the Promise in case of an error
            reject(error);
          }
        });
      });
    }
    } else {
      // Return a rejected promise if the condition is not satisfied
      return Promise.reject("FormId or myDealFormId is not available.");
    }
  }

  private _findDifferencesBeforeSaveData() {
     const currentNewDealSetupObject = this._setSharepointObjectNewDeal();
     const currentSharepointObjectMyDeal = this._setSharepointObjectMyDeal();
    const newDealSetupDifferences = this._getDifferences(this.initialNewDealSetupObject, currentNewDealSetupObject);
    const myDealDifferences = this._getDifferences(this.initialMyDealObject, currentSharepointObjectMyDeal);
    // console.log("NewDeal diff Current-->"+JSON.stringify(newDealSetupDifferences));
    // console.log("MyDeal diff Current-->"+ JSON.stringify(myDealDifferences));
    return {newDealSetupDifferences,myDealDifferences};
  }

  private _getDifferences(initialData:any, currentData:any) {
    const differences:any = {};
    for (const input in initialData) {
      if (initialData[input] !== currentData[input]) {
        differences[input] = currentData[input];
      }
    }
    return differences;
  }
  BindCurrentSection(target:any){
   this.userCurrentSection=target;
  }
  private _getResponseCodeOk(): string {
    let code = responseCodes.ok;
    switch (true) {
      case this.conflictInputsOnSave:
        code = responseCodes.conflicts;
        break;
      case this.overwriteInputs:
        code = responseCodes.incomingUpdates;
        break;
    }
    return code;
  }
  loadLCSPSeries24(mydealObject:any,sharepointObject:any):any{
    if(mydealObject.Series24Id!=null&&mydealObject.Series24Id!=undefined){
      sharepointObject.Series24=mydealObject.Series24Id;
      sharepointObject.Series24DisplayNames=localStorage.getItem("series24EmailValue")?.toString()||"" ;
      }
      if(sharepointObject.GlobalLCSPId?.results?.length>0){
        sharepointObject.GlobalLCSPId.results.forEach((x:any)=>{
          sharepointObject.GlobalLCSP=sharepointObject.GlobalLCSP+";#"+x.mail;
          sharepointObject.GlobalLCSPDisplayNames=sharepointObject.GlobalLCSPDisplayNames+";#"+x.displayName;
        })
        sharepointObject.GlobalLCSP=sharepointObject.GlobalLCSP.slice(11);
        sharepointObject.GlobalLCSPDisplayNames=sharepointObject.GlobalLCSPDisplayNames.slice(11);
      }
      else{
        sharepointObject.GlobalLCSP = null;
        sharepointObject.GlobalLCSPDisplayNames = null;
      }
      if(sharepointObject.LeadBusinessPartnerId?.results?.length>0){
        sharepointObject.LeadBusinessPartnerId.results.forEach((x:any)=>{
          sharepointObject.LeadBusinessPartner=sharepointObject.LeadBusinessPartner+";#"+x.mail;
          sharepointObject.LeadBusinessPartnerDisplayNames=sharepointObject.LeadBusinessPartnerDisplayNames+";#"+x.displayName;
        })
        sharepointObject.LeadBusinessPartner=sharepointObject.LeadBusinessPartner.slice(11);
        sharepointObject.LeadBusinessPartnerDisplayNames=sharepointObject.LeadBusinessPartnerDisplayNames.slice(11);
      }
      else{
        sharepointObject.LeadBusinessPartner = null;
        sharepointObject.LeadBusinessPartnerDisplayNames = null;
      }
        if(sharepointObject.LeadClientServicePartnerId?.results?.length>0){
        sharepointObject.LeadClientServicePartnerId.results.forEach((x:any)=>{
          sharepointObject.LeadClientServicePartner=sharepointObject.LeadClientServicePartner+";#"+x.mail;
          sharepointObject.LeadClientServicePartnerDisplayNames=sharepointObject.LeadClientServicePartnerDisplayNames+";#"+x.displayName;
        })
        sharepointObject.LeadClientServicePartner=sharepointObject.LeadClientServicePartner.slice(11);
        sharepointObject.LeadClientServicePartnerDisplayNames=sharepointObject.LeadClientServicePartnerDisplayNames.slice(11);
      }
      else{
        sharepointObject.LeadClientServicePartner = null;
        sharepointObject.LeadClientServicePartnerDisplayNames=null;
      }
      // if(sharepointObject.SalesOfficeClientId!=undefined&&sharepointObject.SalesOfficeClientId!=""){
      //   sharepointObject.SalesOfficeClient=sharepointObject.SalesOfficeClientId;
      // }
      if(sharepointObject.SalesOfficeClient!=undefined&&sharepointObject.SalesOfficeClient!=""){
        sharepointObject.SalesOfficeClient=sharepointObject.SalesOfficeClient;
      }
  }
  private  _createDraftForm(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const sharepointObject:any = this._setSharepointObjectNewDeal();
      this.initialNewDealSetupObject = this._setSharepointObjectNewDeal();
      this.initialMyDealObject = this._setSharepointObjectMyDeal();
      sharepointObject.FormStatus="Draft";
      sharepointObject.Status=StatusValue.draft;
      sharepointObject.FormActionGroup="DealTeamMembers";
      this.loadLCSPSeries24(this.initialMyDealObject,sharepointObject);
      this.mainService.createForm(AppSettings.formListName, sharepointObject)
      .then((response:any) => {
        this.formId = response.ID;
        this.Created= response.Created;
        this._createMyDeal().then(myDealResponse => {
          this.formStatus = FormStatusValue.draft;
          //Added here to complete all the service calls
          this.projectOverviewComponent.addCurrentUserInDealTeam(this.currentUser);
          //this.saveInProgress = false;
          resolve(true);
        });
        this._setFolderUrl();
        //this.saveInProgress = false;
        resolve(true);
      })
      .catch((error:any) => {
        this.saveInProgress = false;
        resolve(false);
      });
    });
  }

  private _setFolderUrl() {
    this.documentsComponent.setFormId(this.formId);
    this.documentsComponent.setFormUrl();
  }
  private _adminAssignment(): Promise<AdminAssigned> {
    return new Promise<AdminAssigned>((resolve, reject) => {
      if (!this.adminAssigned) {
        Promise.all([
          this.newDealSetupService.getAdminReviewersGroupMembers(), // all Admin Reviewers
          this.newDealSetupService.getAdminReviewRotationAssignment()] // All users in NewDealAdminReviewRotationAssignment
          )
        .then(([adminGroupMembers, adminCustomListMembers]) => {
          const usersToAdd = this._getUsersToAddRotationAssignment(adminGroupMembers, adminCustomListMembers);
          const usersToDelete = this._getUsersToDeleteRotationAssignment(adminGroupMembers, adminCustomListMembers);

          Promise.all([
            (usersToDelete&&usersToDelete.length>0)?this._deleteUsersRotationAssignment(usersToDelete):true,
            (usersToAdd&&usersToAdd.length>0)?this._addUsersRotationAssignment(usersToAdd):true
          ]).then(() => {
          const areusersModified = (usersToDelete&&usersToDelete.length>0) ||  (usersToAdd&&usersToAdd.length>0);
          Promise.all([areusersModified?this.newDealSetupService.getAdminReviewRotationAssignment():true]).then((listUpdated:any) => {
            if(!areusersModified) listUpdated = adminCustomListMembers;
            let nextAdmin: AdminAssigned = this._getAdminToAssign(listUpdated);
            if (Object.keys(nextAdmin)) {
              const objectToSend = this.newDealSetupServiceHelper.createAdminReviewAssignmentObject(nextAdmin.assignmentDate);
              this.mainService.updateRotationAssignment(AppSettings.adminReviewRotationAssignmentList, nextAdmin.adminName,objectToSend).then(() => {
                resolve(nextAdmin);
              });
            } else {
              nextAdmin = this._createAdminObjectToAdd(this.adminAssigned,this.adminAssignedDisplayName);
              resolve(nextAdmin);
            }
          });
          });
        });
      } else {
        const currentAdmin = this._createAdminObjectToAdd(this.adminAssigned,this.adminAssignedDisplayName);
        resolve(currentAdmin);
      }
    });
  }

  private _getAdminToAssign(listUpdated: Array<AdminAssigned>): AdminAssigned {
    let nextAdmin: AdminAssigned | any;
    if (listUpdated.length) {
      nextAdmin = listUpdated[0];
      nextAdmin.assignmentDate =  moment().format("MM/DD/YYYY hh:mm:ss A");
      ;
    }
    return nextAdmin;
  }

  private _createAdminObjectToAdd(adminEmail:string,adminName: string): AdminAssigned {
    const newAdmin = new AdminAssigned();
    newAdmin.assignmentDate = moment().format("MM/DD/YYYY hh:mm:ss A");
    newAdmin.adminName = adminName;
    newAdmin.adminEmail = adminEmail;
    return newAdmin;
  }

  private _getUsersToAddRotationAssignment(adminGroupMembers:any, adminCustomListMembers: Array<AdminAssigned>) {
    const userToAdd:any = [];
    adminGroupMembers.forEach((user:any) => {
      if (!adminCustomListMembers.find(u => u.adminName === (user.LastName+", "+user.FirstName))) {
        userToAdd.push({'Name':user.LastName+", "+user.FirstName,'Email':user.Email});
      }
    });
    return userToAdd;
  }

  private _getUsersToDeleteRotationAssignment(adminGroupMembers:any, adminCustomListMembers: Array<AdminAssigned>): Array<AdminAssigned> {
    const userToDelete: any= [];
    adminCustomListMembers.forEach(user => {
      if (!adminGroupMembers.find((u:any) => (u.LastName+", "+u.FirstName) === user.adminName)) {
        userToDelete.push(user.adminName);
      }
    });
    return userToDelete;
  }

  private _deleteUsersRotationAssignment(usersToDelete: Array<AdminAssigned>): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const count = usersToDelete.length;
      const promises:any = [];
      if (count > 0) {
        this.newDealService.DeleteUsersFromGroup(AppSettings.adminReviewRotationAssignmentList, usersToDelete).then(result=>
          {
            resolve(true);
        }).catch(error=>
          {
           console.error("Error deleting users");
           resolve(false);
        });
      } else {
        resolve(true);
      }
    });
  }

  private _addUsersRotationAssignment(usersToAdd:any): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const count = usersToAdd.length;
      const requestObjects:any = [];
      if (count > 0) {
        usersToAdd.forEach((element:any) => {
          const reqObject = this._createRotationRequestObject(element);
          requestObjects.push(reqObject);
        });
        this.newDealService.AddUserstoGroup(AppSettings.adminReviewRotationAssignmentList, requestObjects).then(result=>
          {
             resolve(true);
        }).catch(error=>
          {
           console.error("Error adding users");
           resolve(false);
        });
      } else {
        resolve(true);
      }
    });
  }
  LoadSeries24Values(sharepointObjectMyDeal:any){
    if(sharepointObjectMyDeal.Series24Id!=null&&sharepointObjectMyDeal.Series24Id!=undefined){
      sharepointObjectMyDeal.Series24=this.initialMyDealObject.Series24Id;
      sharepointObjectMyDeal.Series24DisplayNames=localStorage.getItem("series24EmailValue")?.toString()||"" ;
      }
      if(sharepointObjectMyDeal.SalesOffice!=undefined&&sharepointObjectMyDeal.SalesOffice!=""){
        sharepointObjectMyDeal.SalesOffice=sharepointObjectMyDeal.SalesOffice;
      }
      // if(sharepointObjectMyDeal.SalesOfficeId!=undefined&&sharepointObjectMyDeal.SalesOfficeId!=""){
      //   sharepointObjectMyDeal.SalesOffice=sharepointObjectMyDeal.SalesOfficeId;
      // }
  }
  private _createMyDeal(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const sharepointObjectMyDeal = this._setSharepointObjectMyDeal();
      this.LoadSeries24Values(sharepointObjectMyDeal);
      this.mainService.createListItem(AppSettings.myDealFormList, sharepointObjectMyDeal).then((myDeal:any) => {
        this.myDealFormId = myDeal.ID;
        this.mainService.myDealFormId = myDeal.ID;
        this.initialMyDealList.Created = myDeal.Created;
        const engagementHealthObject = this.newDealSetupServiceHelper.createEngagementObjectToSend(this.formId, myDeal.ID,sharepointObjectMyDeal);
        const newDealObject = this.newDealSetupServiceHelper.createNewDealObjectToSend(myDeal.ID);
        Promise.all([
          this.mainService.createEngagementListData(AppSettings.engagementObjectList, engagementHealthObject),
          this.mainService.updateForm(AppSettings.formListName, this.formId, newDealObject,this.Created)])
          .then(([engObjectResponse,newDealResponse]) => {
            this.engagementId = engObjectResponse.ID;
            if(this.requestSaveFromDealTeam){
               this._checkDealTeamTypeEvent(this.dealTeamRow);
            }
              else{
                //Commented due to executing before the service calls completion
                //this.LoadURLWithFormID();
              }
            resolve(true);
          });

      }).catch((error:any) => {
        this.saveInProgress = false;
        reject(false);
      });
    });
  }

  private _updateFormItem(differences:any): Promise<boolean> {
    const objectNewDeal = differences.newDealSetupDifferences;
    const objectMyDeal = differences.myDealDifferences;
    const newDealLength = Object.keys(objectNewDeal).length;
    const objectMyDealLength = Object.keys(objectMyDeal).length;
    const currentNewDealObj = this.initialNewDealList;
    return new Promise<boolean>((resolve, reject) => {
      Promise.all([
        (objectNewDeal!=null && objectNewDeal!=undefined && newDealLength>0) ? this.mainService.updateForm(AppSettings.formListName, this.formId, this.LoadNewDealObject(objectNewDeal,true),currentNewDealObj.Created) : true,
        (objectMyDeal!=null && objectMyDeal!=undefined && objectMyDealLength) ? this.mainService.updateForm(AppSettings.myDealFormList, this.myDealFormId, this.LoadMyDealObject(objectMyDeal),this.initialMyDealList.Created) : true])
        .then(([responseFormItem, responseMyDealFormItem]) => {
          if (objectMyDeal.Series24Id) {
            this._updateMyDealDataInForm(objectMyDeal.Series24Id, this.dealTeamList);
          }
          if (responseFormItem && responseMyDealFormItem) {
            resolve(true);
          } else {
            resolve(false);
          }
      });
    });
  }
  LoadNewDealObject(objectNewDeal:any,isReadLCSPValues:any){
    try
    {
      if(objectNewDeal.GlobalLCSPId?.results?.length>0){
        objectNewDeal.GlobalLCSPId.results.forEach((x:any)=>{
          objectNewDeal.GlobalLCSP=objectNewDeal.GlobalLCSP+";#"+x.mail;
          objectNewDeal.GlobalLCSPDisplayNames=objectNewDeal.GlobalLCSPDisplayNames+";#"+x.displayName;
        })
        objectNewDeal.GlobalLCSP=objectNewDeal.GlobalLCSP.slice(11);
        objectNewDeal.GlobalLCSPDisplayNames=objectNewDeal.GlobalLCSPDisplayNames.slice(11);
      }
      else if(objectNewDeal.GlobalLCSPId?.results?.length==0){
          objectNewDeal.GlobalLCSP = null;
         objectNewDeal.GlobalLCSPDisplayNames = null;
      }
      if(objectNewDeal.LeadBusinessPartnerId?.results?.length>0){
        objectNewDeal.LeadBusinessPartnerId.results.forEach((x:any)=>{
          objectNewDeal.LeadBusinessPartner=objectNewDeal.LeadBusinessPartner+";#"+x.mail;
          objectNewDeal.LeadBusinessPartnerDisplayNames=objectNewDeal.LeadBusinessPartnerDisplayNames+";#"+x.displayName;
        })
        objectNewDeal.LeadBusinessPartner=objectNewDeal.LeadBusinessPartner.slice(11);
        objectNewDeal.LeadBusinessPartnerDisplayNames=objectNewDeal.LeadBusinessPartnerDisplayNames.slice(11);
      }
      else if(objectNewDeal.LeadBusinessPartnerId?.results?.length==0){
         objectNewDeal.LeadBusinessPartner = null;
         objectNewDeal.LeadBusinessPartnerDisplayNames = null;
      }
        if(objectNewDeal.LeadClientServicePartnerId?.results?.length>0){
        objectNewDeal.LeadClientServicePartnerId.results.forEach((x:any)=>{
          objectNewDeal.LeadClientServicePartner=objectNewDeal.LeadClientServicePartner+";#"+x.mail;
          objectNewDeal.LeadClientServicePartnerDisplayNames=objectNewDeal.LeadClientServicePartnerDisplayNames+";#"+x.displayName;
        })
        objectNewDeal.LeadClientServicePartner=objectNewDeal.LeadClientServicePartner.slice(11);
        objectNewDeal.LeadClientServicePartnerDisplayNames=objectNewDeal.LeadClientServicePartnerDisplayNames.slice(11);
      }
      else if(objectNewDeal.LeadClientServicePartnerId?.results?.length==0){
         objectNewDeal.LeadClientServicePartner = null;
         objectNewDeal.LeadClientServicePartnerDisplayNames = null;
      }
      if(objectNewDeal.SalesOfficeClient!=undefined&&objectNewDeal.SalesOfficeClient!=""){
        objectNewDeal.SalesOfficeClient=objectNewDeal.SalesOfficeClient;
      }
      // if(objectNewDeal.SalesOfficeClientId!=undefined&&objectNewDeal.SalesOfficeClientId!=""){
      //   objectNewDeal.SalesOfficeClient=objectNewDeal.SalesOfficeClientId;
      // }

      //Added to take care of update issue
      if(isReadLCSPValues){
      objectNewDeal.GlobalLCSPId = null;
      objectNewDeal.LeadBusinessPartnerId = null;
      objectNewDeal.LeadClientServicePartnerId = null;
      }
    }
    catch(e){
    }
   return objectNewDeal;
  }
  LoadMyDealObject(objectMyDeal:any){
    try
    {
      if(objectMyDeal.Series24Id!=undefined&&objectMyDeal.Series24Id!=""){
        objectMyDeal.Series24 = objectMyDeal.Series24Id.toString();
        objectMyDeal.Series24DisplayNames =localStorage.getItem("series24EmailValue")?.toString()||"" ;
       }
       if(objectMyDeal.SalesOffice!=undefined&&objectMyDeal.SalesOffice!=""){
        objectMyDeal.SalesOffice=objectMyDeal.SalesOffice;
      }
      //  if(objectMyDeal.SalesOfficeId!=undefined&&objectMyDeal.SalesOfficeId!=""){
      //   objectMyDeal.SalesOffice=objectMyDeal.SalesOfficeId;
      // }
    }
    catch(e){
    }
   return objectMyDeal;
  }
  private _initializeControlName(formGroup: string, columnName: string, defaultValue:any, responseValue:any) {
    if (!responseValue) {
      this.appFormGroup.get(formGroup)?.get(this.sharePointMapper.getFormControlColumnName(columnName))?.setValue(defaultValue);
    }
  }

  private _populateTooltips() {
    this.tooltipHelperService.getTooltips();
  }



  private _handlerRetrieve(responseFormItem:any) {
    if (responseFormItem) {
      if (this.hasPermission('Access')) 
      {
        this.retrieveForm(responseFormItem);
      } else {
        this._openModal(modalTypes.access);
        this.buttonsVisible = false;
      }
    }  else {
      this._disableAdminReviewFormGroup();
      this._handlerAutoSave();
      //this._populateTooltips();
      this._setDealTeamValues(this.dealTeamList);
      this.spinnerService.hideAutoSaveSpinner();
    }
  }

  private _handlerAutoSave() {
    //if (this.hasPermission('AutoSave')) {
      if (this.appContext.autoSaveEnabled) {
        this.isAutoSaveEnabled=true;
        this._executeAutoSave();
      } else {
        this.isAutoSaveEnabled=false;
        this._handlerSaveButton();
        if(this.formStatus ==  FormStatusValue.completed)
        this.saveButtonVisible = false;
      }
    //}
     //Added newly to hide save button if the form is Discontinued
     if(this.formStatus==FormStatusValue.discontinued){
      this.saveButtonVisible=false;
    }
  }

  private _handlerSaveButton() {
    switch (this.formActionGroup) {
      case FormActionGroupValue.adminReview:
        this.saveButtonVisible = true;
        break;
      case FormActionGroupValue.dealTeam:
        this.saveButtonVisible = true;
        break;
    }
  }


  private _handlerFormStatus() {
    if (this.hasPermission('ReadOnly') || !this.hasPermission('Edit')) {
      this._setFormAsReadonly();
    }
  }

  private _validateDCFUsersBeforeUserAction(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      // Getting Deal Team Members from backend
      this.newDealService.getDealTeamTableList(this.myDealFormId).subscribe((response:any) => {
        let actualDealTeamList = this._loadActualDealTeamList(this.initialMyDealList, response);
        const series24EmailId =
          this.appFormGroup.get(formGroups.myDealFormGroup)?.get(this.sharePointMapper.getFormControlColumnName(FormListFields.series24))?.value;
        this._areUsersBelongToDCFGroup(series24EmailId, actualDealTeamList, this.s24GroupUsers).then(resolve).catch(reject);
      }, reject);
    });
  }

  private _areUsersBelongToDCFGroup(
    series24EmailId: string,
    dealTeamList: Array<PeoplepickerDynamicTableItem>,
    //dcfUsers,
    series24users:any): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        let series24Valid;
        let dealTeamValid;
        this._isSeries24Selected(series24EmailId, series24users).then(isSelected => {
          series24Valid = isSelected;
          dealTeamValid = this._areDealTeamMembersBelongToDCFGroup(dealTeamList);
          this.isAllSwiftRolesSelected = this._areRequiredSwiftRolesAddedInDealTeam(dealTeamList);
          resolve(series24Valid && dealTeamValid);
        });
      });
  }

  private _isSeries24Selected(series24EmailId: string, series24users:any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (series24EmailId) {
        resolve(this._isSeries24ValidUser(series24EmailId, series24users));
      } else {
        resolve(true);
      }
    });
  }

  private _isSeries24ValidUser(email: string, series24users:any): boolean {
    let valid = true;
    if (email && this._isInGroup(email, series24users)) {
      if (!this._isInAdGroup(email, JSON.parse((localStorage as any ).getItem("adGroupMember"))!=null?JSON.parse((localStorage as any).getItem("adGroupMember")):this.adGroupMember.value)) {
        this.projectOverviewComponent.setSeries24WarningMessage(true);
        valid = false;
      } else {
        this.projectOverviewComponent.setSeries24WarningMessage(false);
      }
    } else {
      this.appFormGroup.get(formGroups.myDealFormGroup)?.get(this.sharePointMapper.getFormControlColumnName(FormListFields.series24))?.setValue(null);
      this.projectOverviewComponent.setSeries24WarningMessage(false);
    }
    return valid;
  }

  private _areDealTeamMembersBelongToDCFGroup(dealTeamList: Array<PeoplepickerDynamicTableItem>): boolean {
    let valid = true;
    if (dealTeamList) {
      dealTeamList.forEach(user => {
        let email:any="";
        if(user.usersSelected[0].mail)
           email= user.usersSelected[0].mail;
          else
            email= user.usersSelected[0].EntityData.Email;
        if (!this._isInAdGroup(email, JSON.parse((localStorage as any).getItem("adGroupMember"))!=null?JSON.parse((localStorage as any).getItem("adGroupMember")):this.adGroupMember.value)) {
          user.usersSelected[0].LeftDCF = true;
          valid = false;
        }
      });
      this._setDealTeamValues(dealTeamList);
    }
    return valid;
  }

  private _areRequiredSwiftRolesAddedInDealTeam(dealTeamList: Array<PeoplepickerDynamicTableItem>): boolean {
    let valid = true;
    let swiftRoles = AppSettings.madatoryDealTeamSwiftRoles;
    if (swiftRoles && dealTeamList !=null && dealTeamList.length > 0) {
      swiftRoles.forEach(role => {
        if (!this._isSwiftRoleInDealTeam(role, dealTeamList)) {
            valid = false;
        }
      });

      this._setDealTeamValues(dealTeamList);
    }
    return valid;
  }

  private _setDealTeamValues(dealTeamList: Array<PeoplepickerDynamicTableItem>) {
    if (this.projectOverviewComponent.getUpdateDealTeam()) {
      this.projectOverviewComponent.setDealTeamValues(dealTeamList);
      this.prdComponent.setDealTeamValues(dealTeamList);
      this.mercuryOpportunityComponent.setDealTeamValues(dealTeamList);
    }
  }

  private _getFolderUrl() {
    return this.documentsComponent.getFolderUrl();
  }

  private _getFilesPendingToBeUploaded() {
    return this.documentsComponent.getFilesPendingToBeUploaded();
  }

  private _saveFileToLibrary() {
    this.documentsComponent.saveFileToLibrary(this.formId);
  }

  private _isInGroup(email: string, group:any): boolean {
    return group.find((user:any) => {
      if(user && email&&user.Email)
           return user.Email.toLowerCase() === email.toLowerCase();
      return false;
    });
  }
  private _isInAdGroup(email: string, group:Array<any>): boolean {
  if(group.length!=0)
    return group.find(user => user.userPrincipalName.toLowerCase() ===email.toLowerCase());
    else
    return false;
    //return group.find(user => user.Email === email);
  }

  private _isSwiftRoleInDealTeam(role:string,dealteam:Array<PeoplepickerDynamicTableItem>):boolean{
    let selectedSwiftroles = dealteam.map(obj => obj.optionsSelectedThirdColumn);
    return selectedSwiftroles.some(r=>r.value === role);
  }

  private _isDCFMember(groups: Array<string>): boolean {
    const complianceGroup = AppSettings.dcfComplianceGroup;
    return !!groups.find(group => group === complianceGroup);
  }

  private _isDCFMemberInAdGroup(email:string,groups:Array<any>): boolean {

    return !!groups.find(group => group.userPrincipalName.toLowerCase() === email.toLowerCase());
  }

  private _setFormId() {
    this.formId = Number(this.route.snapshot.queryParams.id);
  }

  private _executeAutoSave() {
    this.subscription = interval(this.appContext.autoSaveInterval).subscribe(value => {
      this._validateEmptyForm().then( emptyForm => {
        if (!emptyForm && !this.executingWorkflowChangeAction) {
          this.isAutoSaveSpinnerEnabled=true;
          this.spinnerService.showAutoSaveSpinner();
          this.isRedirect=true;
          this.saveForm()
          .then(responseCode => {
            if (responseCode !== responseCodes.fail) {
              this._showAutosaveAlertMessage(responseCode);
            } else {
              this._stopAutosave();
            }
            timer(this.appContext.autoSaveSpinningWheelInterval).subscribe(x=>{this.spinnerService.hideAutoSaveSpinner();
               //this.sidebarComponent.onclick(this.userCurrentSection);//Added this to refocus to the User Section
            });
          })
          .catch(response => {
            this._stopAutosave();
            timer(this.appContext.autoSaveSpinningWheelInterval).subscribe(x=>{this.spinnerService.hideAutoSaveSpinner()});
            this._openModal(modalTypes.error);
          });
        }
      });
    });
  }

  private _showAutosaveAlertMessage(code:any) {
    let autoSaveMessage;
    const currentTimestamp = this.dateService.FormatDate(new Date().toString());
    switch (code) {
      case responseCodes.ok:
        autoSaveMessage = AppSettings.autosaveOk.replace('{timestamp}', currentTimestamp);
        this.alertMessageType = alertTypes.success;
        //this.alert.displayAlertMessage(autoSaveMessage);
        this.alertService.success(autoSaveMessage,alertOptions);
        break;
      case responseCodes.incomingUpdates:
        autoSaveMessage = AppSettings.autosaveWithUpdates.replace('{timestamp}', currentTimestamp);
        this.alertMessageType = alertTypes.success;
        //this.alert.displayAlertMessage(autoSaveMessage);
        this.alertService.success(autoSaveMessage,alertOptions);
        break;
      case responseCodes.conflicts:
        autoSaveMessage = AppSettings.autosaveWithConflicts.replace('{timestamp}', currentTimestamp);
        this.alertMessageType = alertTypes.warning;
       // this.alert.displayAlertMessage(autoSaveMessage);
       this.alertService.warn(autoSaveMessage,alertOptions);
        break;
      case responseCodes.tryAgain:
        setTimeout(() => this._subscribeAutosave(), AppSettings.forceItemReleaseInterval);
        break;
      case responseCodes.formUpdated:
        this.executingWorkflowChangeAction = true;
        this._openModal(modalTypes.formUpdated);
        this._stopAutosave();
        break;
    }
  }

  private _stopAutosave() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.alertService.info("AutoSave Stopped!",alertOptions);
    }
  }

  private _subscribeAutosave() {
    this.saveForm().then(responseCode => {
      if (responseCode !== responseCodes.fail) {
        this._showAutosaveAlertMessage(responseCode);
      }
    });
  }

  private _validateEmptyForm(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const changesObject:any = Object.assign({}, this._setSharepointObjectNewDeal(), this._setSharepointObjectMyDeal());
      let emptyForm = true;
      if (this.formId) {
        emptyForm = false;
      } else {
        Object.keys(changesObject).forEach( (field:any) => {
          const value:any = changesObject[field];
          let defaultValue = (DefaultFieldsValues as any)[field];
          if (field === AppSettings.defaultDateField) {
            defaultValue = this.dateFormatterService.formatDateToSend(defaultValue);
          }
          if ((this._isValidFieldValue(value) && defaultValue && value !== defaultValue) ||
              (this._isValidFieldValue(value) && !defaultValue)) {
              emptyForm = false;
          }
        });
      }
      resolve(emptyForm);
    });
  }

  private _isValidFieldValue(value:any) {
    if (value === '' || typeof(value) === undefined || value === null || (value.results && value.results.length === 0)) {
      return false;
    }
    return true;
  }

  private _createForm() {
    this.appFormGroup = new FormGroup({
      myDealFormGroup  : new FormGroup({
        clientLegalName : new CustomFormControl('Client Legal Name', ''),
        clientID : new CustomFormControl('Client #', null),
        engagementType : new CustomFormControl('Engagement Type', {value: 'CLIENT ENGAGEMENT WITH PURSUIT (PRD)', disabled: true}),
        engagementName : new CustomFormControl('Project Name', ''),
        projectStartDate  : new CustomFormControl('Project Start Date', null),
        projectEndDate : new CustomFormControl('Project End Date', null),
        isRecurringWBS : new CustomFormControl('Is Recurring WBS', {value: 'NO', disabled: true}),
        deloitteUSEntity : new CustomFormControl('Deloitte US Entity', 37),
        salesOffice : new CustomFormControl('Sales Office', null),
        isInternationalEngagement : new CustomFormControl('Is International Engagement', ''),
        areResourcesWorkOutside : new CustomFormControl('Are Resources work outside', {value: '', disabled: true}),
        memberFirmTaxablePresence : new CustomFormControl('Have Member Firm Taxable Presence', {value: '', disabled: true}),
        otherMemberFirmsParticipating : new CustomFormControl('Other Member Firms Participating on Engagement',
        {value: '', disabled: true}),
        whereWorkMemberFirms : new CustomFormControl('Where Work Member Firms', {value: '', disabled: true}),
        isServiceSameAsClientAddress  : new CustomFormControl('Is Service-At Adress same as Client Address', {value: 'YES', disabled: true}),
        billingAddressSameClientAddress : new CustomFormControl('Is Billing Address same as Client Address', {value: 'YES', disabled: true}),
        billingAddress : new CustomFormControl('BillingAddress', { value : '', disabled : true }),
        series24 : new CustomFormControl('Series 24', null),
        clientType : new CustomFormControl('Client Type', ''),
        referralSourceName : new CustomFormControl('Referral Source Name', ''),
        ebitda : new CustomFormControl('EBITDA', ''),
        feeType : new CustomFormControl('Fee Type', ''),
        //buyerName : new CustomFormControl('Buyer Name', ''),
        // buyerNameClient : new CustomFormControl('Buyer Name', ''),
        transactionType : new CustomFormControl('Transaction Type', ''),
        integratedMarketOffering :
        new CustomFormControl('Integrated Market Offering', {value: 'M&A Transaction CFMO', disabled: true}),
        engagementIndustrySICCode : new CustomFormControl('Engagement Industry SIC Code', ''),
        payerAddressSameClientAddress : new CustomFormControl('Is payer Address same as Client Address', {value: 'YES', disabled: true}),
        payerAddress : new CustomFormControl('Payer Address', { value : '', disabled : true }),
        serviceAtAddress : new CustomFormControl('Service-At Address', { value : '', disabled : true }),
        marketOffering : new CustomFormControl('Market Offering', {value: '', disabled: true}),
        material : new CustomFormControl('Material', {value: 'Management Consulting', disabled: true}),
        streetAddress : new CustomFormControl('Street Address', ''),
        city : new CustomFormControl('City', ''),
        state : new CustomFormControl('State / Province / Region / Country', ''),
        postalCode : new CustomFormControl('Postal Code', ''),
        telephone : new CustomFormControl('Telephone Number', ''),
        industry : new CustomFormControl('Industry', ''),
        subsector : new CustomFormControl('Subsector', ''),
        isCompetitivePitch : new CustomFormControl('Is this a Competitive Pitch', ''),
        source : new CustomFormControl('Source', ''),
        previousAuditor : new CustomFormControl('Previous Auditor', ''),
        currentAuditor : new CustomFormControl('Current Auditor', ''),
        tickerCode : new CustomFormControl('Ticker code', {value: '', disabled: true}),
        isPubliclyTradedEquity : new CustomFormControl('Publicly Traded Equity', ''),
        opportunityID : new CustomFormControl('Mercury Opportunity Code', {value: '', disabled: true}),
      }),
      projectOverViewFormGroup : new FormGroup({
        dealteamTable : new CustomFormControl('Deal Team', []),
        isOpportunityViaGlobalSharepoint :new CustomFormControl('Should this opportunity be shared via the global sharepoint?',''),
        internationalCommunity : new CustomFormControl('What is your request for the international community', { value : '', disabled : true }),
        //referralPercentage : new CustomFormControl('Referral Percentage', ''),

     }),
     clientOverViewFormGroup : new FormGroup({
        leadClientServicePartner : new CustomFormControl('Lead Client Service Partner ', { results: [] }),
        leadBusinessPartner : new CustomFormControl('Lead Business Partner', { results: [] }),
        globalLCSP : new CustomFormControl('Global LCSP', { results: [] }),
        doingBusinessAs : new CustomFormControl('Doing Business As', ''),
     }),
     newDealFormGroup : new FormGroup({
        clientRevenue : new CustomFormControl('Client Revenue', ''),
        statusNewDeal : new CustomFormControl('Status', ''),
        businessDescription : new CustomFormControl('Business description', ''),
        publiclyTradedSecurities : new CustomFormControl('Publicly Traded Securities', ''),
        isPubliclyTradedDebt : new CustomFormControl('Publicly Traded Debt', ''),
        cusip : new CustomFormControl('Cusip', {value: '', disabled: true}),
        firmType : new CustomFormControl('Firm Type',''),
        conflictCheckCompletion : new CustomFormControl('Conflict Check completion date', null),
        obaPstPersonnelConflict : new CustomFormControl('OBA/PST Personnel Conflict Check Completed', ''),
        obaPstPersonnelConflictDate : new CustomFormControl('OBA/PST Personnel Conflict Check Date', {value: null, disabled: true}),
        backgroundCheckCompletion : new CustomFormControl('IndusBackground Check completion datetry', null),
        ofacCompletion : new CustomFormControl('OFAC completion date', null),
        commentsNewDeal : new CustomFormControl('Comments', ''),
        competitorsList: new CustomFormControl('Competitor List', ''),
        otherCompetitor: new CustomFormControl('Other Competitor', {value: '', disabled: true}),
        sourceName : new CustomFormControl('Source Name', ''),
     }),
     mercuryOpportunityFormGroup : new FormGroup({
        maConfidentialSubsidiary : new CustomFormControl('M&A Confidential Subsidiary', ''),
        opportunityPhase : new CustomFormControl('Opportunity Phase', {value: 'Request to Propose', disabled: true}),
        totalValue : new CustomFormControl('Total Value ($000s)', {value: '750000', disabled: false}),
        //buyerRole : new CustomFormControl('Buyer Role', ''),
        initiative : new CustomFormControl('Initiative', {value: 'N/A', disabled: true}),
        //isThisAReproposal : new CustomFormControl('Is this a re-proposal?', ''),
       isThisARecurringOpportunity: new CustomFormControl('Is this a recurring opportunity?', { value: 'NO', disabled: true }),
        //annualOpportunity : new CustomFormControl('Annual Opportunity', {value: '', disabled: true}),
        //isThisAPullThroughOpportunity : new CustomFormControl('Is this a pull through opportunity?', ''),
        isThisAFollowOnOpportunity: new CustomFormControl('Is this a follow on opportunity?', { value: 'NO', disabled: true }),
        covid19RelatedNewOpportunity : new CustomFormControl('COVID19 Related New Opportunity?', ''),
        category : new CustomFormControl('Category', {value: 'Mergers, Acquisitions & Divestitures', disabled: false}),
        subCategory : new CustomFormControl('Sub-Category', {value: 'MA&D - Corporate Finance', disabled: false}),
        offering : new CustomFormControl('Offering', ''),
        subcontractor : new CustomFormControl('Subcontractor', { value: 'None', disabled: true }),
        allianceProduct : new CustomFormControl('Alliance Product', {value: 'None', disabled: true}),
        pursuitStartDate : new CustomFormControl('Pursuit Start Date', null),
        expectedCloseDate : new CustomFormControl('Expected Pursuit Close', null),
        memberFirmRecognizingRevenue : new CustomFormControl('Member Firm Recognizing Revenue', ''),
        //originalOpportunityID : new CustomFormControl('Original Opportunity ID #', {value: '', disabled: true}),
       isIndiaInvolved: new CustomFormControl('Is India Involved', ''),
       isPriorDESattendee:new CustomFormControl('Prior DES attendee?',''),
       engagementIndustrySector: new CustomFormControl('Engagement Industry Sector', ''),
       isDeloitteAlumniInvolved: new CustomFormControl('Is Deloitte Alumni Involved', ''),
       deloitteAlumniName: new CustomFormControl('Deloitte Alumni Name', { value: '', disabled: true }),
       deloitteAlumniRole: new CustomFormControl('Deloitte Alumni Role', { value: '', disabled: true }),
       crossBusinessOffering: new CustomFormControl('Cross Business Offering', { value: 'DCF M&A Transaction', disabled: true }),
     }),
     clientSetupFormGroup : new FormGroup({
      salesOfficeClient : new CustomFormControl('Sales Office', null),
      deloitteUSEntityClient : new CustomFormControl('Deloitte US Entity', null),
      ownershipTypeI1 : new CustomFormControl('Ownership Type I1', ''),
      ownershipTypeI2 : new CustomFormControl('Ownership Type I2', ''),
      ownershipTypeI13 : new CustomFormControl('Ownership Type I13', ''),
      //dateBecameClient : new CustomFormControl('Date became client', null),
      //financialYearEnd : new CustomFormControl('Financial year end', new Date(new Date().getFullYear(), 11, 31)),
      //consultingSICCode : new CustomFormControl('Consulting SIC code', ''),
      globalUltimateParentID : new CustomFormControl('Global Ultimate Parent ID', ''),
      globalClientPortfolio : new CustomFormControl('Global Client Portfolio', ''),
      globalClientIntelligenceNumber : new CustomFormControl('Global Client Intelligence Number', ''),
      gst : new CustomFormControl('GST', ''),
      buyerNameClient : new CustomFormControl('Buyer Name', ''),//buyerNameClient refers to Main Client Contact
      buyerTitle : new CustomFormControl('Buyer Title', ''),
      //buyerEmail : new CustomFormControl('Buyer Email', ''),
      attestStatus : new CustomFormControl('Attest Status', ''),
      attestStatusParent : new CustomFormControl('Attest Status Parent', ''),
      memberFirmPerformingAttest : new CustomFormControl('Member Firm performing Attest', ''),
      entityOnWhichPerformsAttestWork : new CustomFormControl('Entity on which performs Attest work', ''),
      currentAuditorTenureExpiryDate : new CustomFormControl('Current Auditor Tenure Expiry Date', null),
      auditorStartDate : new CustomFormControl('Auditor Start Date', null),
      pcaobReview : new CustomFormControl('PCAOB Review', ''),
      descDesignation : new CustomFormControl('Desc Description', ''),
      approvingPartnerTelephone : new CustomFormControl('Approving Partner Telephone Number', ''),
      clientAcceptanceProcedures : new CustomFormControl('Client Acceptance Procedures followed', ''),
      reasonForBypassingProcedures :
      new CustomFormControl('Reason for bypassing Client Acceptance Procedures', {value: '', disabled : true}),
      anySpecialInstructions : new CustomFormControl('Any Special Instructions', '')
     }),
     adminReviewFormGroup : new FormGroup({
      salesforceCode : new CustomFormControl('Salesforce Code'),
      swiftCode : new CustomFormControl('Swift Code'),
     }),
     documentsFormGroup : new FormGroup({
      documentTypesAttachments : new CustomFormControl('DocumentTypes Attachments', [])
     }),
     activeNewDealFormGroup: new FormGroup({
      isActiveNewDealNotificationSent : new CustomFormControl('Is Active WBS Notification Sent',{value: null , disabled: false})
     })
    });
  }

  private _handlerAdminReviewSection(adminApprovalDate: string, adminApprovalName: string) {
    this._disableAdminReviewFormGroup();
    this.changeDetector.detectChanges();
    if (this.checkVisibilityAdminReviewSetion()) {
      const adminReviewEditable = this._isAdminReviewEditable();
      this.isAdminReviewEditable=adminReviewEditable;
      this.sidebarComponent.showAdminReview();
      this.adminReviewComponent.setAdminApprovalStatus(adminReviewEditable, adminApprovalDate, adminApprovalName);
      if (adminReviewEditable) {
        this._enableAdminReviewFormGroup();
      }
    }
    this.isValidateFormInAdminReview();
  }
  isValidateFormInAdminReview(){
    if(this.formActionGroup!="AdminReview"){
      this.isAdminReviewEditable=true;
    }
   
  }
  private _isAdminReviewEditable() {
    return this.hasPermission('EditAdminSection');
  }

  private _isAdminReviewVisible() {
    return this.hasPermission('ShowAdminSection');
  }

  private _isFormDiscontinuedByDealTeam(discontinuedFormActionGroup: string) {
    return discontinuedFormActionGroup === FormActionGroupValue.dealTeam && this.formStatus === FormStatusValue.discontinued;
  }

  private _enableAdminReviewFormGroup() {
    this.appFormGroup.get(formGroups.adminReviewFormGroup)?.enable();
    const myDealFormGroup = this.appFormGroup.get(formGroups.myDealFormGroup) as FormGroup;
    myDealFormGroup.controls.opportunityID.enable();
  }

  private _disableAdminReviewFormGroup() {
    this.appFormGroup.get(formGroups.adminReviewFormGroup)?.disable();
    const myDealFormGroup = this.appFormGroup.get(formGroups.myDealFormGroup) as FormGroup;
    myDealFormGroup.controls.opportunityID.disable();
  }

  private _setValidators(validators: Array<SharepointValidator>) {
    Object.keys(this.appFormGroup.controls).forEach(formControlGroupName => {
      const formGroup: FormGroup = this.appFormGroup.get(formControlGroupName) as FormGroup;
      this.sharePointMapper.setFormControlGroupValidators(formGroup, validators);
    });
  }

  private _initialSetValidators() {
    // this.mainService.getValidationsListItems().then((response:any) => {
    //   this._setValidators(response);
    //   this.initialFormValidators = response;
    // });
    this.newDealService.getAsteriskList().subscribe({
      next: (result: any) => {
        //This needs to be removed after migration correction
        if(result!= undefined && result.length > 0)
        {
          result.forEach((element:any) => {
            if(element.FieldName == 'SalesOfficeId')
            {
              element.FieldName = 'SalesOffice'
            }
            if(element.FieldName == 'SalesOfficeClientId')
            {
              element.FieldName = 'SalesOfficeClient'
            }
            if(element.FieldName == 'DeloitteUSEntityClientId')
            {
              element.FieldName = 'DeloitteUSEntityClient'
            }
          });
        }
        this._setValidators(result);
      this.initialFormValidators = result;

      },
      error: (err: any) => {
        console.log("Error" + err)
      }
    })
  }

  private _openModal(modalType: string) {
    this.modalType = modalType;
    this.notifypopupComponent.openPopup('NewDeal',modalType);

  }

  private _checkDealTeamTypeEvent(dealTeamRow: PeoplePickerSelectedRow) {
    if (dealTeamRow.actionType === DynamicTableAction.Add) {
      this._storeDealTeamRow(dealTeamRow);
    } else {
      this._deleteDealTeamRow(dealTeamRow);
    }
  }
LoadURLWithFormID(){
  this.spinnerService.hideAutoSaveSpinner();
  let url = window.location.href;
  if(!url.includes("?")&&this.isRedirect){
    if(this.formId!=undefined){document.location.href=url+"?id="+this.formId;}
  }
}
  private _deleteDealTeamRow(dealTeamRow: PeoplePickerSelectedRow) {
     const dealTeamAuditObject: object = {
      DealTeamMember: dealTeamRow.item.usersSelected[0].displayName,
      FormID:this.myDealFormId,
      CreatedBy:this.currentUserName,
      Action:dealTeamRow.actionType,
      FormName:AppSettings.formListName,
      EngagementName:this.appFormGroup.get(formGroups.myDealFormGroup).get(this.sharePointMapper.getFormControlColumnName(FormListFields.engagementName)).value
      };
    this.mainService.deleteListItem(AppSettings.dealTeamTableList, this.myDealFormId.toString(),dealTeamRow.item.itemId.toString()),
    this.mainService.createListItem(AppSettings.dealTeamAuditLog, dealTeamAuditObject)
    .then(([response, auditLogId]) => {   
    this._updateDealTeamMembers(this.dealTeamList);
    })
    .catch((error:any) => {
      console.log(error);
    });
  }

  private _storeDealTeamRow(dealTeamRow: PeoplePickerSelectedRow) {
    const objectToSend = this._createDealTeamObject(dealTeamRow.item);
    var engagementName = this.appFormGroup.get(formGroups.myDealFormGroup).get(this.sharePointMapper.getFormControlColumnName(FormListFields.engagementName)).value;
    const addDealTeamAuditObject: object = {
      DealTeamMember: dealTeamRow.item.usersSelected[0].displayName,
      FormID:objectToSend.FormId,
      CreatedBy:this.currentUserName,
      Action:dealTeamRow.actionType,
      FormName:AppSettings.formListName,
      Email: objectToSend.Email,
      EngagementName:engagementName
      };
    if (!dealTeamRow.item.itemId) {
      this.mainService.createListItem(AppSettings.dealTeamTableList, objectToSend)
      .then((itemId:any) => {
        this.projectOverviewComponent.setItemPeoplePicker(itemId, dealTeamRow.item.index);
        // Add Audit log for creation of Deal Team Member
        this.mainService.createListItem(AppSettings.dealTeamAuditLog,addDealTeamAuditObject )
            .then((respadd:any) => {
              this._updateDealTeamMembers(this.dealTeamList,true,dealTeamRow.item.usersSelected[0].mail);
            });        
      })
      .catch((error:any) => {
      });
    } else {
     var isNameChanged=false;
      this.mainService.getAuditLogDealTeamMemebers(AppSettings.dealTeamTableList, this.myDealFormId) .then((response) => {
        var dealTeamName;
        response?.forEach(element => {
          if(objectToSend.Name!=element.Name && dealTeamRow.item.itemId==element.ID)
          {
            dealTeamName=element.Name;
            const removeDealTeamAuditObject: object = {
              DealTeamMember: dealTeamName,
              FormID:objectToSend.FormId,
              CreatedBy:this.currentUserName,
              Action:DynamicTableAction.Delete,
              FormName:AppSettings.formListName,
              Email: element.Email,
              EngagementName:engagementName
              };
      this.mainService.createListItem(AppSettings.dealTeamAuditLog,removeDealTeamAuditObject ),
      this.mainService.createListItem(AppSettings.dealTeamAuditLog,addDealTeamAuditObject)
      .then(([respadd,respremove]) => {
      });
        isNameChanged = true;
          }
      });
         
        var initiatorEmail = "";
        var dealTeamRowItemId = (dealTeamRow.item.itemId as any)?.ID || dealTeamRow.item.itemId 
        objectToSend.ID = dealTeamRowItemId;
        this.mainService.updateListItem(AppSettings.dealTeamTableList, dealTeamRowItemId, objectToSend)
          .then((response:any) => {
            if(isNameChanged)
              initiatorEmail = objectToSend.Email
           this._updateDealTeamMembers(this.dealTeamList,isNameChanged,initiatorEmail);
      })
      .catch(error => {
      });
        });
      }

  }


  private _updateDealTeamMembers(responseDealTeamTable:any,sendNotification:boolean=false,notifierEmail:string="") {
    this.spinnerService.hideAutoSaveSpinner();
    const myDealDataObject =  { DealTeamMembersDisplayNames : "",DealTeamMembers:""};
    let dealTeamMembers:any = [];
    let dealTeamMemberEmails:any = [];
     if (responseDealTeamTable && responseDealTeamTable.length > 0) {
         responseDealTeamTable.forEach( (item:any) => {
           dealTeamMembers = dealTeamMembers.concat(item.usersSelected[0].displayName);
           dealTeamMemberEmails = dealTeamMemberEmails.concat(item.usersSelected[0].mail);
         });
         const concatenatedString = dealTeamMembers.join(';#');
         myDealDataObject.DealTeamMembersDisplayNames = concatenatedString;
         const emailString = dealTeamMemberEmails.join(';#');
         myDealDataObject.DealTeamMembers =emailString;
     }
    this.saveInProgress = true;
    Promise.all([this.mainService.updateForm(AppSettings.myDealFormList, this.myDealFormId, myDealDataObject,this.initialMyDealList.Created),
                 this.mainService.updateForm(AppSettings.formListName, this.formId, myDealDataObject,this.initialNewDealList.Created,false,sendNotification,notifierEmail,this.engagementId)])
    .then( () => {
      this.saveInProgress = false;
    this.LoadURLWithFormID();
    })
    .catch(error => {
    });
  }

  private _updateMyDealDataInForm( responseSeries24:any, responseDealTeamTable:any) {
    const myDealDataObject =  { DealTeamMembersDisplayNames : "",Series24: "",Series24DisplayNames:"",DealTeamMembers:""};
    let dealTeamMembers:any = [];
    let dealTeamMemberEmails:any = [];
     if (responseDealTeamTable && responseDealTeamTable.length > 0) {
         responseDealTeamTable.forEach( (item:any) => {
           dealTeamMembers = dealTeamMembers.concat(item.usersSelected[0].displayName);
           dealTeamMemberEmails = dealTeamMemberEmails.concat(item.usersSelected[0].mail);
         });
         const concatenatedString = dealTeamMembers.join(';#');
         myDealDataObject.DealTeamMembersDisplayNames = concatenatedString;
         const emailString = dealTeamMemberEmails.join(';#');
         myDealDataObject.DealTeamMembers =emailString;
     }


    if (responseSeries24) {
      myDealDataObject.Series24 = responseSeries24.toString();
     myDealDataObject.Series24DisplayNames =localStorage.getItem("series24EmailValue")?.toString()||"" ;
     localStorage.removeItem("series24EmailValue");

    }
    this.saveInProgress = true;
    this.mainService.updateForm(AppSettings.formListName, this.formId, myDealDataObject,this.initialNewDealList.Created)
    .then( () => {
      this.saveInProgress = false;
    })
    .catch(error => {
      this.saveInProgress = false;
      this._stopAutosave();
    });
  }

  private _createDealTeamObject(dealTeamRow: PeoplepickerDynamicTableItem) {
    const dealteamObject = new DealTeamObject();
    // dealteamObject.NameId = dealTeamRow.idUsersSelected[0];
    // dealteamObject.RoleId = Number(dealTeamRow.optionsSelectedFirstColumn.key);
    // dealteamObject.PursuitRoleId = Number(dealTeamRow.optionsSelectedSecondColumn.key);
    // dealteamObject.SwiftRoleId =  Number(dealTeamRow.optionsSelectedThirdColumn.key);
    // dealteamObject.FormId = this.myDealFormId;
    dealteamObject.Name = dealTeamRow.usersSelected[0].displayName;
    dealteamObject.Role = String(dealTeamRow.optionsSelectedFirstColumn.value);
    dealteamObject.PursuitRole = String(dealTeamRow.optionsSelectedSecondColumn.value);
    dealteamObject.SwiftRole =  String(dealTeamRow.optionsSelectedThirdColumn.value);
    dealteamObject.FormId = this.myDealFormId.toString();
    dealteamObject.Email = dealTeamRow.usersSelected[0].mail;

    return dealteamObject;
  }

  // private _checkCompetitorTypeEvent(competitorItem: SelectedDynamicTableValue) {
  //   if (competitorItem.actionType === DynamicTableAction.Add) {
  //     this._storeCompetitorItem(competitorItem);
  //   } else {
  //     this._deleteCompetitorItem(competitorItem);
  //   }
  // }

  // private _deleteCompetitorItem(competitorItem: SelectedDynamicTableValue) {
  //   this.mainService.deleteListItem(AppSettings.competitorsList, competitorItem.item.itemId)
  //   .then(response => {
  //   })
  //   .catch(error => {
  //   });
  // }

  // private _storeCompetitorItem(competitorItem: SelectedDynamicTableValue) {
  //   const objectToSend = this._createCompetitorItemObject(competitorItem.item);
  //   if (!competitorItem.item.itemId) {
  //     this.mainService.createListItem(AppSettings.competitorsList, objectToSend)
  //     .then(itemId => {
  //       this.newDealComponent.setItemCompetitor(itemId, competitorItem.item.index);
  //     })
  //     .catch(error => {
  //     });
  //   } else {
  //     this.mainService.updateListItem(AppSettings.competitorsList, competitorItem.item.itemId, objectToSend)
  //     .then(response => {
  //     })
  //     .catch(error => {
  //     });
  //   }
  // }

  // private _createCompetitorItemObject(competitor: SelectDynamicTableItem) {
  //   const competitorItemObject = new CompetitorItemObject();
  //   competitorItemObject.CompetitorId = Number(competitor.competitorItem.key);
  //   competitorItemObject.FormId = this.formId;

  //   return competitorItemObject;
  // }

  private _storeAdditionalDocumentTypeItem(additionalDocumentTypeItem: any) {
    const objectToSend = this._createAdditionalDocumentTypeItemObject(additionalDocumentTypeItem);
    this.fileService.AddAdditionalDocumentTypeAtachment(objectToSend).subscribe(
      {
        next: (response2: any) =>
        {
        },error:(err2:any)=>{}
      }
    )

  }

  private _createAdditionalDocumentTypeItemObject(additionalDocumentTypeItem: any) {
    const additionalDocumentTypeItemObject = new AdditionalDocumentTypeItemObject();
    const userName = localStorage.getItem("AuditorFullName");
    additionalDocumentTypeItemObject.DocumentType = additionalDocumentTypeItem.documentTitle;
    additionalDocumentTypeItemObject.FormID = this.formId;
    additionalDocumentTypeItemObject.CreatedBy =  userName==null?"":userName;
    additionalDocumentTypeItemObject.Created =  "";
    additionalDocumentTypeItemObject.ID = "";
    return additionalDocumentTypeItemObject;
  }

  private _adminApprovalAction() {
    this.spinnerService.showAutoSaveSpinner();
    this._validateDCFUsersBeforeUserAction().then(areValid => {
      if (areValid) {
        this._setValidators(this.initialFormValidators);
        this.formSubmitted = true;
        if (this.appFormGroup.valid) {
          if (this.isAllSwiftRolesSelected) {
            if (!this._getFilesPendingToBeUploaded()) {
              this._stopAutosave();
              this.saveForm().then(response => {
                this.newDealSetupService.updateFormOnAdminReviewApproval(this.initialNewDealList.Created,this.formId).then(() => {
                  this.spinnerService.hideAutoSaveSpinner();
                  this._openModal(modalTypes.approval);
                });
              });
            } else {
              this.spinnerService.hideAutoSaveSpinner();
              this._openModal(modalTypes.filesUploading);
            }
          } else {
            this.spinnerService.hideAutoSaveSpinner();
            this._openModal(modalTypes.validateSwiftRoles);
          }
        } else {
          this.spinnerService.hideAutoSaveSpinner();
          this._openModal(modalTypes.mandatoryFields);
        }
      } else {
        this.spinnerService.hideAutoSaveSpinner();
        this._openModal(modalTypes.userLeftDcf);
      }
    });
  }

  private _adminReturnAction() {
    this._setValidators([]);
    this.formSubmitted = true;
    if (this.appFormGroup.valid) {
      this._stopAutosave();
      this._openModal(modalTypes.returnForRework);
    } else {
      this._openModal(modalTypes.mandatoryFields);
    }
  }

  private _setSharepointObjectMyDeal(): SharepointObjectMyDeal {
    let sharepointObject = new SharepointObjectMyDeal();
    const myDealFormGroup:any = this.appFormGroup.get(formGroups.myDealFormGroup);

    sharepointObject = {
      ...sharepointObject,

      // My Deal
      ...this.sharePointMapper.fillSharepointObject(myDealFormGroup as FormGroup),

      StartDate:
      this.dateFormatterService.formatDateToSend(myDealFormGroup.get(this.sharePointMapper.getFormControlColumnName(FormListFields.projectStartDate))?.value) as any,

      EndDate:
      this.dateFormatterService.formatDateToSend(myDealFormGroup
      .get(this.sharePointMapper.getFormControlColumnName(FormListFields.projectEndDate))?.value) as any,
    };

    return sharepointObject;
  }

  private _setSharepointObjectNewDeal(): SharepointObjectNewDeal {
    let sharepointObject:any = new SharepointObjectNewDeal();

    sharepointObject = {
      ...sharepointObject,

      // Project overview
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.projectOverViewFormGroup) as FormGroup),

      // Client overview
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.clientOverViewFormGroup) as FormGroup),

      // New Deal
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.newDealFormGroup) as FormGroup),
      ObaPstPersonnelConflictDate:
        this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.newDealFormGroup)?.get(this.
          sharePointMapper.getFormControlColumnName(FormListFields.obaPstPersonnelConflictDate))?.value) as any,
      BackgroundCheckCompletion:
        this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.newDealFormGroup)?.get(this.sharePointMapper
          .getFormControlColumnName(FormListFields.backgroundCheckCompletion))?.value) as any,
      OfacCompletion: this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.newDealFormGroup)?.get(this.sharePointMapper.
        getFormControlColumnName(FormListFields.ofacCompletion))?.value) as any,
      ConflictCheckCompletion:
        this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.newDealFormGroup)?.get(this.sharePointMapper
          .getFormControlColumnName(FormListFields.conflictCheckCompletion))?.value) as any,

      // Mercury
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.mercuryOpportunityFormGroup) as FormGroup),
      PursuitStartDate:
        this.dateFormatterService.formatDateToSend(this.appFormGroup
        .get(formGroups.mercuryOpportunityFormGroup)?.get(this.sharePointMapper.getFormControlColumnName(FormListFields.pursuitStartDate))?.value) as any,
      ExpectedCloseDate:
        this.dateFormatterService.formatDateToSend(this.appFormGroup
        .get(formGroups.mercuryOpportunityFormGroup)?.get(this.
          sharePointMapper.getFormControlColumnName(FormListFields.expectedCloseDate))?.value) as any,

      // Client setup
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.clientSetupFormGroup) as FormGroup),
      // DateBecameClient:
      //   this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.clientSetupFormGroup)
      //   .get(this.sharePointMapper.getFormControlColumnName(FormListFields.dateBecameClient)).value),
      // FinancialYearEnd:
      //   this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.clientSetupFormGroup)
      //   .get(this.sharePointMapper.getFormControlColumnName(FormListFields.financialYearEnd)).value),
      CurrentAuditorTenureExpiryDate:
        this.dateFormatterService.formatDateToSend(
          this.appFormGroup.get(formGroups.clientSetupFormGroup)?.get(this
            .sharePointMapper.getFormControlColumnName(FormListFields.currentAuditorTenureExpiryDate))?.value
        ) as any,
      AuditorStartDate:
      this.dateFormatterService.formatDateToSend(this.appFormGroup.get(formGroups.clientSetupFormGroup)?.get(this.
        sharePointMapper.getFormControlColumnName(FormListFields.auditorStartDate))?.value) as any,

      // Admin review
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.adminReviewFormGroup) as FormGroup),

      //Active New Deal Notification
      ...this.sharePointMapper.fillSharepointObject(this.appFormGroup.get(formGroups.activeNewDealFormGroup)as FormGroup),
    };

    //delete sharepointObject.CompetitorsList;
    delete sharepointObject.DealTeamTable;

    return sharepointObject;
  }


  adminActiveNewDealHandler(adminActiveSwiftCodeAction:any){
    this.appFormGroup.get(formGroups.activeNewDealFormGroup)?.get('isActiveNewDealNotificationSent')?.setValue('Ready');
    const objectToSend = {IsActiveNewDealNotificationSent:"Ready"};
    this.mainService.updateForm(AppSettings.formListName,this.formId,objectToSend,this.initialNewDealList.Created,true).then(result => {
      if (result) {
        this.spinnerService.hideAutoSaveSpinner();
        this._openModal(modalTypes.activeNewDealNotification);
      }
      else{
        this.spinnerService.hideAutoSaveSpinner();
        this._openModal(modalTypes.error);
      }
    });
  }
  private _createSharepointMapper() {
    this.sharePointMapper = new NewDealSetupSharepointMapper();
    // My Deal
    this._fillMyDeal();

    // Proyect overview
    this._fillProyectOverview();

    // Client overview
    this._fillClientOverview();

    // New Deal
    this._fillNewDeal();

    // Mercury
    this._fillMercury();

    // Client setup
    this._fillClientSetUp();

    // Admin review
    this._fillAdminReview();

    // Documents
    this._fillDocuments();

    this._fillActiveNewDeal();
  }

  private _fillActiveNewDeal(){
    this.sharePointMapper.addMapping('IsActiveNewDealNotificationSent','isActiveNewDealNotificationSent');
  }

  private _fillMyDeal() {
    this.sharePointMapper.addMapping('ClientName', 'clientLegalName');
    this.sharePointMapper.addMapping('ClientID', 'clientID');
    this.sharePointMapper.addMapping('EngagementType', 'engagementType');
    this.sharePointMapper.addMapping('StartDate', 'projectStartDate');
    this.sharePointMapper.addMapping('EndDate', 'projectEndDate');
    this.sharePointMapper.addMapping('IsRecurringWBS', 'isRecurringWBS');
    this.sharePointMapper.addMapping('DeloitteUSEntityId', 'deloitteUSEntity');
    this.sharePointMapper.addMapping('IntegratedMarketOffering', 'integratedMarketOffering');
    //this.sharePointMapper.addMapping('SalesOfficeId', 'salesOffice');
    this.sharePointMapper.addMapping('SalesOffice', 'salesOffice');
    this.sharePointMapper.addMapping('EngagementIndustrySICCode', 'engagementIndustrySICCode');
    this.sharePointMapper.addMapping('IsInternationalEngagement', 'isInternationalEngagement');
    this.sharePointMapper.addMapping('AreResourcesWorkOutside', 'areResourcesWorkOutside');
    this.sharePointMapper.addMapping('MemberFirmTaxablePresence', 'memberFirmTaxablePresence');
    this.sharePointMapper.addMapping('OtherMemberFirmsParticipating', 'otherMemberFirmsParticipating');
    this.sharePointMapper.addMapping('WhereWorkMemberFirms', 'whereWorkMemberFirms');
    this.sharePointMapper.addMapping('ServiceAtAddress', 'serviceAtAddress');
    this.sharePointMapper.addMapping('MarketOffering', 'marketOffering');
    this.sharePointMapper.addMapping('IsServiceSameAsClientAddress', 'isServiceSameAsClientAddress');
    this.sharePointMapper.addMapping('Material', 'material');
    this.sharePointMapper.addMapping('BillingAddressSameClientAddress', 'billingAddressSameClientAddress');
    this.sharePointMapper.addMapping('BillingAddress', 'billingAddress');
    this.sharePointMapper.addMapping('PayerAddressSameClientAddress', 'payerAddressSameClientAddress');
    this.sharePointMapper.addMapping('PayerAddress', 'payerAddress');
    this.sharePointMapper.addMapping('TransactionType', 'transactionType');
    this.sharePointMapper.addMapping('EngagementName', 'engagementName');
    this.sharePointMapper.addMapping('Series24Id', 'series24');
    this.sharePointMapper.addMapping('StreetAddress', 'streetAddress');
    this.sharePointMapper.addMapping('City', 'city');
    this.sharePointMapper.addMapping('State', 'state');
    this.sharePointMapper.addMapping('PostalCode', 'postalCode');
    this.sharePointMapper.addMapping('Telephone', 'telephone');
    this.sharePointMapper.addMapping('Industry', 'industry');
    this.sharePointMapper.addMapping('ClientType', 'clientType');
    this.sharePointMapper.addMapping('ReferralSourceName', 'referralSourceName');
    this.sharePointMapper.addMapping('Subsector', 'subsector');
    this.sharePointMapper.addMapping('IsCompetitivePitch', 'isCompetitivePitch');
    this.sharePointMapper.addMapping('EBITDA', 'ebitda');
    this.sharePointMapper.addMapping('FeeType', 'feeType');
   // this.sharePointMapper.addMapping('BuyerName', 'buyerName');
    // this.sharePointMapper.addMapping('BuyerName', 'buyerNameClient');
    this.sharePointMapper.addMapping('ReferralSource', 'source');
    this.sharePointMapper.addMapping('PreviousAuditor', 'previousAuditor');
    this.sharePointMapper.addMapping('CurrentAuditor', 'currentAuditor');
    this.sharePointMapper.addMapping('TickerCode', 'tickerCode');
    this.sharePointMapper.addMapping('IsPubliclyTradedEquity', 'isPubliclyTradedEquity');
    //this.sharePointMapper.addMapping('OriginalOpportunityID', 'originalOpportunityID'); // this is retrieving from new deal in mercury section
    this.sharePointMapper.addMapping('OpportunityID', 'opportunityID');
  }

  private _fillProyectOverview() {
    this.sharePointMapper.addMapping('DealTeamTable', 'dealteamTable');
    this.sharePointMapper.addMapping('OpportunityViaGlobalSharepoint', 'isOpportunityViaGlobalSharepoint');
    this.sharePointMapper.addMapping('InternationalCommunity', 'internationalCommunity');
    //this.sharePointMapper.addMapping('ReferralPercentage', 'referralPercentage');
  }

  private _fillClientOverview() {
    this.sharePointMapper.addMapping('LeadClientServicePartnerId', 'leadClientServicePartner');
    this.sharePointMapper.addMapping('LeadBusinessPartnerId', 'leadBusinessPartner');
    this.sharePointMapper.addMapping('GlobalLCSPId', 'globalLCSP');
    this.sharePointMapper.addMapping('DoingBusinessAs', 'doingBusinessAs');
  }

  private  _fillNewDeal() {
    this.sharePointMapper.addMapping('ClientRevenue', 'clientRevenue');
    this.sharePointMapper.addMapping('StatusNewDeal', 'statusNewDeal');
    this.sharePointMapper.addMapping('BusinessDescription', 'businessDescription');
    this.sharePointMapper.addMapping('PubliclyTradedSecurities', 'publiclyTradedSecurities');
    this.sharePointMapper.addMapping('IsPubliclyTradedDebt', 'isPubliclyTradedDebt');
    this.sharePointMapper.addMapping('Cusip', 'cusip');
    this.sharePointMapper.addMapping('FirmType', 'firmType');
    this.sharePointMapper.addMapping('ObaPstPersonnelConflict', 'obaPstPersonnelConflict');
    this.sharePointMapper.addMapping('CommentsNewDeal', 'commentsNewDeal');
    this.sharePointMapper.addMapping('ObaPstPersonnelConflictDate', 'obaPstPersonnelConflictDate');
    this.sharePointMapper.addMapping('BackgroundCheckCompletion', 'backgroundCheckCompletion');
    this.sharePointMapper.addMapping('OfacCompletion', 'ofacCompletion');
    this.sharePointMapper.addMapping('ConflictCheckCompletion', 'conflictCheckCompletion');
    this.sharePointMapper.addMapping('CompetitorsList', 'competitorsList');
    this.sharePointMapper.addMapping('OtherCompetitor', 'otherCompetitor');
    this.sharePointMapper.addMapping('SourceName', 'sourceName');

  }

  private _fillMercury() {
    this.sharePointMapper.addMapping('M_AConfidentialSubsidiary', 'maConfidentialSubsidiary');
    this.sharePointMapper.addMapping('OpportunityPhase', 'opportunityPhase');
    this.sharePointMapper.addMapping('TotalValue', 'totalValue');
    //this.sharePointMapper.addMapping('BuyerRole', 'buyerRole');
    this.sharePointMapper.addMapping('Initiative', 'initiative');
    //this.sharePointMapper.addMapping('IsThisAReproposal', 'isThisAReproposal');
    this.sharePointMapper.addMapping('IsThisARecurringOpportunity', 'isThisARecurringOpportunity');
    //this.sharePointMapper.addMapping('AnnualOpportunity', 'annualOpportunity');
    //this.sharePointMapper.addMapping('IsThisAPullThroughOpportunity', 'isThisAPullThroughOpportunity');
    this.sharePointMapper.addMapping('IsThisAFollowOnOpportunity', 'isThisAFollowOnOpportunity');
    this.sharePointMapper.addMapping('COVID19RelatedNewOpportunity', 'covid19RelatedNewOpportunity');
    this.sharePointMapper.addMapping('Category', 'category');
    this.sharePointMapper.addMapping('SubCategory', 'subCategory');
    this.sharePointMapper.addMapping('Offering', 'offering');
    this.sharePointMapper.addMapping('Subcontractor', 'subcontractor');
    this.sharePointMapper.addMapping('AllianceProduct', 'allianceProduct');
    this.sharePointMapper.addMapping('PursuitStartDate', 'pursuitStartDate');
    this.sharePointMapper.addMapping('ExpectedCloseDate', 'expectedCloseDate');
    this.sharePointMapper.addMapping('MemberFirmRecognizingRevenue', 'memberFirmRecognizingRevenue');
    //this.sharePointMapper.addMapping('OriginalOpportunityID', 'originalOpportunityID');
    this.sharePointMapper.addMapping('IsIndiaInvolved', 'isIndiaInvolved');
    this.sharePointMapper.addMapping('IsPriorDESattendee','isPriorDESattendee');
    this.sharePointMapper.addMapping('EngagementIndustrySector', 'engagementIndustrySector');
    this.sharePointMapper.addMapping('DeloitteAlumniInvolved', 'isDeloitteAlumniInvolved');
    this.sharePointMapper.addMapping('DeloitteAlumniName', 'deloitteAlumniName');
    this.sharePointMapper.addMapping('DeloitteAlumniRole', 'deloitteAlumniRole');
    this.sharePointMapper.addMapping('CrossBusinessOffering', 'crossBusinessOffering');
  }

  private _fillClientSetUp() {
    // this.sharePointMapper.addMapping('SalesOfficeClientId', 'salesOfficeClient');
    // this.sharePointMapper.addMapping('DeloitteUSEntityClientId', 'deloitteUSEntityClient');
    this.sharePointMapper.addMapping('SalesOfficeClient', 'salesOfficeClient');
    this.sharePointMapper.addMapping('DeloitteUSEntityClient', 'deloitteUSEntityClient');
    this.sharePointMapper.addMapping('OwnershipTypeI1', 'ownershipTypeI1');
    this.sharePointMapper.addMapping('OwnershipTypeI2', 'ownershipTypeI2');
    this.sharePointMapper.addMapping('OwnershipTypeI13', 'ownershipTypeI13');
    //this.sharePointMapper.addMapping('DateBecameClient', 'dateBecameClient');
    //this.sharePointMapper.addMapping('FinancialYearEnd', 'financialYearEnd');
    //this.sharePointMapper.addMapping('ConsultingSICCode', 'consultingSICCode');
    this.sharePointMapper.addMapping('GlobalUltimateParentID', 'globalUltimateParentID');
    this.sharePointMapper.addMapping('GlobalClientPortfolio', 'globalClientPortfolio');
    this.sharePointMapper.addMapping('GlobalClientIntelligenceNumber', 'globalClientIntelligenceNumber');
    this.sharePointMapper.addMapping('GST', 'gst');
     //Sharepoint field BuyerName stores the value in buyerNameClient i.e Main Client Contact UI field
    this.sharePointMapper.addMapping('BuyerName', 'buyerNameClient');
    this.sharePointMapper.addMapping('BuyerTitle', 'buyerTitle');
    //this.sharePointMapper.addMapping('BuyerEmail', 'buyerEmail');
    this.sharePointMapper.addMapping('AttestStatus', 'attestStatus');
    this.sharePointMapper.addMapping('AttestStatusParent', 'attestStatusParent');
    this.sharePointMapper.addMapping('MemberFirmPerformingAttest', 'memberFirmPerformingAttest');
    this.sharePointMapper.addMapping('EntityOnWhichPerformsAttestWork', 'entityOnWhichPerformsAttestWork');
    this.sharePointMapper.addMapping('CurrentAuditorTenureExpiryDate', 'currentAuditorTenureExpiryDate');
    this.sharePointMapper.addMapping('AuditorStartDate', 'auditorStartDate');
    this.sharePointMapper.addMapping('PcaobReview', 'pcaobReview');
    this.sharePointMapper.addMapping('DescDesignation', 'descDesignation');
    this.sharePointMapper.addMapping('ApprovingPartnerTelephone', 'approvingPartnerTelephone');
    this.sharePointMapper.addMapping('ClientAcceptanceProcedures', 'clientAcceptanceProcedures');
    this.sharePointMapper.addMapping('ReasonForBypassingProcedures', 'reasonForBypassingProcedures');
    this.sharePointMapper.addMapping('AnySpecialInstructions', 'anySpecialInstructions');
  }

  private _fillAdminReview() {
    this.sharePointMapper.addMapping('SalesforceCode', 'salesforceCode');
    this.sharePointMapper.addMapping('SwiftCode', 'swiftCode');
  }

  private _fillDocuments() {
    this.sharePointMapper.addMapping('DocumentTypesAttachments', 'documentTypesAttachments');
  }

  private _setSharepointMapper() {
    this.projectOverviewComponent.setSharepointMapper(this.sharePointMapper);
    this.newDealComponent.setSharepointMapper(this.sharePointMapper);
    this.mercuryOpportunityComponent.setSharepointMapper(this.sharePointMapper);
    this.prdComponent.setSharepointMapper(this.sharePointMapper);
    this.clientSetupComponent.setSharepointMapper(this.sharePointMapper);
  }

  private _populateProjectOverviewSection(responseFormItem:any, dealTeamList: Array<PeoplepickerDynamicTableItem>) {
    this.dealTeamList=dealTeamList;
    if(!this.isAutoSaveUpdate){
    let actualDealTeamList= this._loadActualDealTeamList(responseFormItem,dealTeamList);
    this.dealTeamList=actualDealTeamList;
    }
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.projectOverViewFormGroup) as FormGroup, responseFormItem);
    this.projectOverviewComponent.evaluateIsOpportunityViaGlobalSharepoint(responseFormItem.OpportunityViaGlobalSharepoint);
    this.appFormGroup.get(formGroups.projectOverViewFormGroup)?.get(this
      .sharePointMapper.getFormControlColumnName(FormListFields.dealTeamTable))?.setValue(this.dealTeamList);
    this._setDealTeamValues(this.dealTeamList);
  }
  _loadActualDealTeamList(responseFormItem:any,dealTeamList:any){
    let dealTeamValues=new Array<PeoplepickerDynamicTableItem>();
    let indexValue=0;
    dealTeamList.forEach((x:any)=>{
    let dataObject=new PeoplepickerDynamicTableItem();
    dataObject.optionsSelectedFirstColumn=this.loadDropDownValues(x,"First");
    dataObject.optionsSelectedSecondColumn=this.loadDropDownValues(x,"Second");
    dataObject.optionsSelectedThirdColumn=this.loadDropDownValues(x,"Third");
    dataObject.itemId=x.ID;
    dataObject.usersSelected.push(this.loadDealTeamMember(responseFormItem,x,indexValue));
    dealTeamValues.push(dataObject);
    indexValue++;
    })
    return dealTeamValues;
}
LoadDropdownLists(){
  this.firstColumnItemList = this.mainService.dealTeamRoleList;
  // this.dropdownService.fillLookupOptionsNew(AppSettings.dealTeamRole,'Role').then(
  //   (response:any) => {
  //     // Success block
  //    this.firstColumnItemList=response;
  //   }).catch((error:any) =>{console.error('Error fetching data:', error);})

  this.secondColumnItemList=this.mainService.dealTeamPursuitRoleList;
    //   this.dropdownService.fillLookupOptionsNew(AppSettings.dealTeamPursuitRole,'Role').then(
    // (response:any) => {
    //   // Success block
    //   this.secondColumnItemList=response;
    // }).catch((error:any) =>{console.error('Error fetching data:', error);})

    this.thirdColumnItemList=this.mainService.dealTeamSwiftRoleList;
    // this.dropdownService.fillLookupOptionsNew(AppSettings.dealTeamSwiftRole,'Role').then(
    //   (response:any) => {
    //     // Success block
    //     this.thirdColumnItemList=response;
    //   }).catch((error:any) =>{console.error('Error fetching data:', error);})
}

fillingDropdownKeyValues(row:any,columnIndex:any){
  let key: any="";
  if (columnIndex == "First") {
    this.firstColumnItemList.forEach((x: any) => {
      if (x.value == row.Role) {
        key = x.key;
      }
    })
  }
  else if (columnIndex == "Second") {
    this.secondColumnItemList.forEach((x: any) => {
      if (x.value == row.PursuitRole) {
        key = x.key;
      }
    })
  }
  else {
    this.thirdColumnItemList.forEach((x: any) => {
      if (x.value == row.SwiftRole) {
        key = x.key;
      }
    })
  }
  return key;
}
loadDropDownValues(data:any,columnIndex:any){
  const dropdownItem = new DropdownItem();
  if (columnIndex == "First") {
    dropdownItem.key = this.fillingDropdownKeyValues(data,columnIndex);
    dropdownItem.value = data.Role;
  }
  else if (columnIndex == "Second") {
    dropdownItem.key = this.fillingDropdownKeyValues(data,columnIndex);
    dropdownItem.value = data.PursuitRole;
  }
  else {
    dropdownItem.key = this.fillingDropdownKeyValues(data,columnIndex);
    dropdownItem.value = data.SwiftRole;
  }
  return dropdownItem;
}
loadDealTeamMember(responseFormItem:any,data: any,index:any) {
  let obj = {} as PeoplePickerUser;
  obj.DisplayText = data.Name;
  obj.Key = data.ID;
  obj.displayName = data.Name;
  obj.mail=data.Email;//this.loadEmailIdFromObject(responseFormItem,index);
  obj.LeftDCF = this._isInAdGroup(obj.mail, JSON.parse((localStorage as any).getItem("adGroupMember"))!=null?JSON.parse((localStorage as any).getItem("adGroupMember")):this.adGroupMember.value)===undefined?true:false
  return obj;
}
loadEmailIdFromObject(responseFormItem:any,index:any):string{
  let value="";
  if (responseFormItem.DealTeamMembers != ""&&responseFormItem.DealTeamMembers != undefined) {
    let emailLists = responseFormItem.DealTeamMembers.split(";#");
    if (emailLists.length - 1 >= index) {
      value= emailLists[index].toString();
    }
  }
  else {
    value= "";
  }
return value;
}
  private _populateClientOverviewSection(responseFormItem:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.clientOverViewFormGroup) as FormGroup, responseFormItem);
    var leadClientServicePartnerResponse= this._getLCSPPeoplepickerUserData(responseFormItem.LeadClientServicePartnerDisplayNames?.split(";#"));
    var globalLCSPResponse= this._getLCSPPeoplepickerUserData(responseFormItem.GlobalLCSPDisplayNames?.split(";#"));
    var leadBusinessPartnerResponse= this._getLCSPPeoplepickerUserData(responseFormItem.LeadBusinessPartnerDisplayNames?.split(";#"));

      this.clientOverviewComponent.setLeadClientServicePartner(leadClientServicePartnerResponse);
      this.clientOverviewComponent.setLeadBusinessPartner(leadBusinessPartnerResponse);
      this.clientOverviewComponent.setGlobalLCSP(globalLCSPResponse);
    // this._setPeoplePickers(formGroups.clientOverViewFormGroup, this.sharePointMapper.getFormControlColumnName(
    //   FormListFields.leadClientServicePartner), responseFormItem.LeadClientServicePartnerDisplayNames);
    // this._setPeoplePickers(formGroups.clientOverViewFormGroup, this.sharePointMapper.getFormControlColumnName(
    //   FormListFields.leadBusinessPartner), responseFormItem.LeadBusinessPartnerDisplayNames);
    // this._setPeoplePickers(formGroups.clientOverViewFormGroup, this.sharePointMapper.getFormControlColumnName(
    //   FormListFields.globalLCSP), responseFormItem.GlobalLCSPId);
  }
  private _getLCSPPeoplepickerUserData(response:any): Array<PeoplePickerUser>{

      const peoplePickerUserList = new Array<PeoplePickerUser>();

      if (response!=undefined) {
        for (var lcspuser of response) {
          let user=new PeoplePickerUser();
          user.displayName=lcspuser;
          peoplePickerUserList.push(user);
          //user=
        }
      } else {
        //resolve(peoplePickerUserList);
      }
      return peoplePickerUserList;
  }
  private _getLCSPPeoplepickerUserDataForNewDeal(responseemail:any,responseName:any): any{

    const peoplePickerUserList = new Array<PeoplePickerUser>();
    if(responseemail!=null&&responseemail!=undefined&&responseemail!=""){
    if(responseemail.includes(";#")){
      let lcspemail=responseemail.split(";#");
      let lcspname=responseName.split(";#");
    if (lcspemail.length==lcspname.length) {
      for (let i=0;i<=lcspemail.length-1;i++) {
        let user=new PeoplePickerUser();
        user.displayName=lcspname[i];
        user.mail=lcspemail[i];
        user.DisplayText=lcspname[i];
        peoplePickerUserList.push(user);
        //user=
      }
    } else {
      this._openModal(modalTypes.lcspmismatch)
      //resolve(peoplePickerUserList);
    }
  }
  else{
    let user=new PeoplePickerUser();
    user.displayName=responseName;
    user.mail=responseemail;
    user.DisplayText=responseName;
    peoplePickerUserList.push(user);
  }
}
    return peoplePickerUserList;
}

  private _populateNewDealSection(responseFormItem:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.newDealFormGroup) as FormGroup, responseFormItem);
    this.appFormGroup.get(formGroups.newDealFormGroup)?.get(this.
      sharePointMapper.getFormControlColumnName(FormListFields.ofacCompletion))?.setValue(
      this.dateFormatterService.formatDateOnRetrieve(responseFormItem.OfacCompletion));
    this.appFormGroup.get(formGroups.newDealFormGroup)?.get(this
      .sharePointMapper.getFormControlColumnName(FormListFields.obaPstPersonnelConflictDate))?.setValue(
      this.dateFormatterService.formatDateOnRetrieve(responseFormItem.ObaPstPersonnelConflictDate));
    this.appFormGroup.get(formGroups.newDealFormGroup)?.get(this
      .sharePointMapper.getFormControlColumnName(FormListFields.conflictCheckCompletion))?.setValue(
      this.dateFormatterService.formatDateOnRetrieve(responseFormItem.ConflictCheckCompletion));
    this.appFormGroup.get(formGroups.newDealFormGroup)?.get(this
      .sharePointMapper.getFormControlColumnName(FormListFields.backgroundCheckCompletion))?.setValue(
      this.dateFormatterService.formatDateOnRetrieve(responseFormItem.BackgroundCheckCompletion));

    // this.appFormGroup.get(formGroups.newDealFormGroup)
    // .get(this.sharePointMapper.getFormControlColumnName(FormListFields.competitorsList)).setValue(responseCompetitorsList);

    this.newDealComponent.evaluateIsPubliclyTradedDebtDependency(responseFormItem.IsPubliclyTradedDebt);
    this.newDealComponent.evaluateObaPstPersonnelConflictDependency(responseFormItem.ObaPstPersonnelConflict);
    //this.newDealComponent.setCompetitorsValues(responseCompetitorsList);
  }

  private _populateMyDealSection(responseFormItem:any) {
    responseFormItem = {
      ...responseFormItem,
      StartDate: this.dateFormatterService.formatDateOnRetrieve(responseFormItem.StartDate),
      EndDate: this.dateFormatterService.formatDateOnRetrieve(responseFormItem.EndDate),
    };
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.myDealFormGroup) as FormGroup, responseFormItem);
     this.prdComponent.evaluateIsThisAInternationalEngagementDependency(responseFormItem.IsInternationalEngagement);
     this.clientSetupComponent.evaluateBillingAddressDependency(responseFormItem.BillingAddressSameClientAddress);
     this.clientSetupComponent.evaluatePayerAddressDependency(responseFormItem.PayerAddressSameClientAddress);
    this.newDealComponent.evaluateIsCompetitivePitchDependency(responseFormItem.IsCompetitivePitch);
     this.newDealComponent.evaluateIsPubliclyTradedEquityDependency(responseFormItem.IsPubliclyTradedEquity);
    this.clientSetupComponent.evaluateServiceAddressDependency(responseFormItem.IsServiceSameAsClientAddress);
  }
  private _populateMercuryOpportunitySection(responseFormItem:any) {
    responseFormItem = {
      ...responseFormItem,
      PursuitStartDate: this.dateFormatterService.formatDateOnRetrieve(responseFormItem.PursuitStartDate),
      ExpectedCloseDate: this.dateFormatterService.formatDateOnRetrieve(responseFormItem.ExpectedCloseDate)
    };

    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.mercuryOpportunityFormGroup) as FormGroup, responseFormItem);
    this.mercuryOpportunityComponent.evaluateIsThisARecurringOpportunityDependency(responseFormItem.IsThisARecurringOpportunity);
   this.mercuryOpportunityComponent.evaluateIsDeloitteAlumniDependency(responseFormItem.DeloitteAlumniInvolved);
    this.mercuryOpportunityComponent.evaluateIsThisAPullThroughOpportunityDependency(responseFormItem.IsThisAPullThroughOpportunity);

  }

  private _populateClientSetupSection(responseFormItem:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.clientSetupFormGroup) as FormGroup, responseFormItem);
    // this.appFormGroup.get(formGroups.clientSetupFormGroup)
    // .get(this.sharePointMapper.getFormControlColumnName(FormListFields.dateBecameClient)).setValue(
    //   this.dateFormatterService.formatDateOnRetrieve(responseFormItem.DateBecameClient));
    // this.appFormGroup.get(formGroups.clientSetupFormGroup)
    // .get(this.sharePointMapper.getFormControlColumnName(FormListFields.financialYearEnd)).setValue(
    //   this.dateFormatterService.formatDateOnRetrieve(responseFormItem.FinancialYearEnd));
    this.appFormGroup.get(formGroups.clientSetupFormGroup)?.get(this
      .sharePointMapper.getFormControlColumnName(FormListFields.currentAuditorTenureExpiryDate))?.setValue(
      this.dateFormatterService.formatDateOnRetrieve(responseFormItem.CurrentAuditorTenureExpiryDate));
    this.appFormGroup.get(formGroups.clientSetupFormGroup)?.get(this
      .sharePointMapper.getFormControlColumnName(FormListFields.auditorStartDate))?.setValue(
      this.dateFormatterService.formatDateOnRetrieve(responseFormItem.AuditorStartDate));

    this.clientSetupComponent.evaluateClientAcceptanceProceduresDependency(responseFormItem.ClientAcceptanceProcedures);
    this.clientSetupComponent.evaluateAttestStatusDependency(responseFormItem.AttestStatus);
    this._handlerSicCode(responseFormItem.ConsultingSICCode, responseFormItem.EngagementIndustrySICCode);
  }

  private _handlerSicCode(consultingSICCode: string, engagementIndustrySICCode: string) {
    if (consultingSICCode && consultingSICCode !== engagementIndustrySICCode)  {
      this.clientSetupComponent.setConsultingSicCode(consultingSICCode);
    }
  }

  private _populateDocumentTypes(responseDocumentTypes: Array<DocumentTypeItem>) {
    this.documentsComponent.populateDocumentTypes(responseDocumentTypes, this.formId);
  }

  private _populateAdditionalDocumentTypes(responseAdditionalDocumentTypes: Array<DocumentTypeItem>) {
    this.documentsComponent.populateAdditionalDocumentTypes(responseAdditionalDocumentTypes);
  }

  private _populateDocumentSection(responseAttachments:any) {
    this.documentsComponent.populateDocumentSection(responseAttachments,this.isAutoSaveSpinnerEnabled);
  }

  private _populateAdminReviewSection(responseFormItem:any) {
    this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.adminReviewFormGroup) as FormGroup, responseFormItem);
  }

  private _setPeoplePickers(appFormGroup: string, inputName: string, userIdList:any) {
    const input:any = this.appFormGroup.get(appFormGroup)?.get(inputName);
    if (!userIdList) {
      input.setValue({ results: [] });
    } else {
      input.setValue({ results: userIdList});
    }
  }

  private _createPeoplePickerUserArrays(): Promise<any> {
    const clientOverViewFormGroup = this.appFormGroup.get(formGroups.clientOverViewFormGroup);
    return Promise.all([
      this._getPeoplepickerUserData(clientOverViewFormGroup?.get(this.sharePointMapper.
        getFormControlColumnName(FormListFields.leadClientServicePartner))?.value.results),
      this._getPeoplepickerUserData(clientOverViewFormGroup?.get(this.sharePointMapper.
        getFormControlColumnName(FormListFields.leadBusinessPartner))?.value.results),
      this._getPeoplepickerUserData(clientOverViewFormGroup?.get(
        this.sharePointMapper.getFormControlColumnName(FormListFields.globalLCSP))?.value.results)])
      .then(([leadClientServicePartnerResponse, leadBusinessPartnerResponse, globalLCSPResponse]) => {
        this.clientOverviewComponent.setLeadClientServicePartner(leadClientServicePartnerResponse);
        this.clientOverviewComponent.setLeadBusinessPartner(leadBusinessPartnerResponse);
        this.clientOverviewComponent.setGlobalLCSP(globalLCSPResponse);
      });
  }

  private _getPeoplepickerUserData(peoplepickerUserIds:any): Promise<Array<PeoplePickerUser>> {
    return new Promise<Array<PeoplePickerUser>>((resolve, reject) => {
      const peoplePickerUserList = new Array<PeoplePickerUser>();
      let requests = peoplepickerUserIds.length;
      if (requests) {
        for (const userId of peoplepickerUserIds) {
            let user=new PeoplePickerUser();
            user.displayName = userId.displayName;
            peoplePickerUserList.push(user);
            requests--;
            if (requests === 0) {
              resolve(peoplePickerUserList);
            }
        }
      } else {
        resolve(peoplePickerUserList);
      }
    });
  }

  private _setFormAsReadonly() {
    Object.keys(this.appFormGroup.controls).forEach(formGroupName => {
      const formGroup = this.appFormGroup.get(formGroupName) as FormGroup;
      Object.keys(formGroup.controls).forEach(formControlName => {
        const elem = formGroup.get(formControlName) as CustomFormControl;
        elem.setReadOnly(true);
      });
    });
  }

  getusercomments(setUserComments:any) {
    this.usercomments=setUserComments
    if(this.usercomments.length>0)
    {
      this.sidebarComponent.showCommentReview();
    }
  }
  getS24GroupData() {
    this.newDealService.GetGroupData(AppSettings.series24Group).then((result)=>
    {
      this.s24GroupUsers=result;
    });    
  }
  ConfirmDetails(event:any) //Handle the popup click
  {
    if(event.isOkayClicked) // Okay is clicked on the popup
    {
        //based on the type propgate the function call
        switch(event.modalType)
        {
          case modalTypes.submit: // redirect to engagement dashboard
          case modalTypes.discontinueConfirmed: //redirect to engagement dashboard          
          case modalTypes.returnForReworConfirmed: // redirect to engagement dashboard          
          case modalTypes.approval: // redirect to engagement dashboard
          case modalTypes.reactivateConfirmed: // redirect to engagement dashboard   
          case modalTypes.retryWorkflow: // redirect to engagement dashboard   
          case modalTypes.formUpdated:  // redirect to engagement dashboard   
          case modalTypes.activeNewDealNotification: // redirect to engagement dashboard   
          case modalTypes.noDCFMember: // stay on same page
          case modalTypes.noValidForm:
          case modalTypes.access: this.redirectOkModal(event.modalType);break; // stay on the same page with freeze
          case modalTypes.validateSwiftRoles:
          case modalTypes.userLeftDcf:
          case modalTypes.mandatoryFields:this.scrollToTop();break;
          case modalTypes.retrieve:this.scrollToSection();break;
          case modalTypes.discontinue:this.discontinueReason=event.discontinueReason;this.confirmDiscontinueReason();break;
          case modalTypes.returnForRework:this.returnForReworkReason=event.returnForReworkReason;this.checkReasonBeforeReturn();break;
          case modalTypes.returnForReworkReason:this.onReturnForRework();break;
          case modalTypes.reactivateForm:this.confirmReactivateForm();break;
          case modalTypes.discontinueReason:this.onDiscontinue();break;
          default:break;
        }
    }
    else //cancel clicked
    {
      switch(event.modalType)
      {
        case modalTypes.discontinue:this.cancelDiscontinue();break;
        case modalTypes.returnForRework:this.cancelReturnForRework();break;
        default:break;
      }
    }
  }
   _createRotationRequestObject(adminDetails: any):any {
    var currentUserName = localStorage.getItem("AuditorFullName");
    let requestObject =
    {
      Admin:adminDetails.Name,
      ID:"",
      LastAssignmentDate:"",
      Created:"",
      CreatedBy:currentUserName,
      Modified:"",
      ModifiedBy:"",
      Email:adminDetails.Email
    };
    return requestObject;
  }

}


